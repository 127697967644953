<template>
  <div class="interface-menu-navbar-header">
    <div class="cadre-block-filter">
      <div class="right-block">
        <!-- DATE DEBUT -->
        <div class="date-input-vuetify mr-1" v-if="checkPermission('GAPTHFDD')">
          <v-menu
            v-model="isDatePickerDebutVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_debut"
                label="Date début"
                outlined
                readonly
                hide-details
                clearable
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                @click:clear="handleResetDate('date_debut')"
                color="#704ad1"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date_debut"
              @input="handleDateTimePicker(date_debut, 'date_debut')"
              no-title
              locale="fr"
              color="#704ad1"
              item-color="#704ad1"
              :allowed-dates="disabledStartDate"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <!-- DATE FIN -->
        <div class="date-input-vuetify mr-1" v-if="checkPermission('GAPTHFDF')">
          <v-menu
            v-model="isDatePickerFinVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_fin"
                label="Date fin"
                outlined
                readonly
                hide-details
                v-on="on"
                v-bind="attrs"
                clearable
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                @click:clear="handleResetDate('date_fin')"
                color="#704ad1"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_fin"
              @input="handleDateTimePicker(date_fin, 'date_fin')"
              no-title
              locale="fr"
              :allowed-dates="disabledEndDate"
              color="#704ad1"
              item-color="#704ad1"
            ></v-date-picker>
          </v-menu>
        </div>
        <!-- COONTENT LOADING AND MESSAGE ERROR -->
        <!-- <div
          v-if="getChargementAppelPaiementThObligee"
          class="chargement chargement-loading-icon"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div> -->
        <v-progress-circular
          v-if="getChargementAppelPaiementThObligee"
          indeterminate
          class="ml-2"
          color="#704ad1"
        ></v-progress-circular>
        <div class="notif-export-file-sci ml-2" v-if="getShowNotifExportFile">
          Préparation du fichier en cours ...
        </div>
        <div class="error-message ml-2 mr-2">
          <div v-if="getErrorAppeliPaiementThObligee">
            <ul
              v-if="Array.isArray(getErrorAppeliPaiementThObligee)"
              class="mb-0"
            >
              <li
                v-for="(e, index) in getErrorAppeliPaiementThObligee"
                :key="index"
              >
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorAppeliPaiementThObligee }}</div>
          </div>
        </div>
        <div class="error-message ml-2">
          <div
            v-if="getErrorAppeliPaiementUpdateThObligee"
            class="error_oblige_update"
          >
            <v-alert type="error" dismissible>{{
              getErrorAppeliPaiementUpdateThObligee
            }}</v-alert>
          </div>
        </div>
      </div>
      <div class="left-block">
        <!-- SEARCH -->
        <div class="serach-input mr-2">
          <search
            :value="searchValue"
            @changeSearchValue="changeSearchValue"
            :dynamic="false"
            v-if="checkPermission('GAPTHRE')"
            placeHolder="Numéro de dossier"
          />
        </div>
        <!-- GENERE FACTURE MASTER -->
        <GenerateFactureMaster
          v-if="checkPermission('GAPTHGFPPM')"
          :functionGenerate="generateFactureMastersObliges"
        />
        <!-- EXPORT RAPPORT OBLIGE -->
        <export-rapport-oblige
          v-if="checkPermission('GAPTHABGR')"
          :genereRapport="exportRapportFactureObligee"
          :filtre="ComputedFilter"
        />
        <!-- VUE OBLGE FACTURE -->
        <vue-oblige-facture />
        <!--ROTATE DATA-->
        <v-tooltip
          bottom
          color="#311B92"
          v-if="checkPermission('GAPTHRL') && computedCheckedRows.length"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              class="block-icon mr-2"
              v-on="on"
              v-bind="attrs"
              @click.stop="refreshAooelPaiementObligee"
            >
              <font-awesome-icon icon="sync-alt" />
            </div>
          </template>
          <span>Actualiser</span>
        </v-tooltip>
        <!-- DELETE PROJET -->
        <deleteProject
          class="ml-1"
          :dataToUse="computedCheckedRowsWithFact"
          v-if="
            checkPermission('GAPTHDP') && computedCheckedRowsWithFact.length
          "
        />
        <!-- Dossier sans organisme -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on, attrs }">
            <div
              size="sm"
              class="block-icon mr-2"
              v-on="on"
              v-bind="attrs"
              v-if="
                missingOrganismeInObligeeFile.length &&
                  checkPermission('GAPTHDSO')
              "
              v-b-modal.missingOrganisme
            >
              <font-awesome-icon icon="house-user" />
            </div>
          </template>
          <span>Dossier sans organisme</span>
        </v-tooltip>
        <!-- INSERTION XLS -->
        <InsertionBDD :phase="1" title="Insertion xls" />
        <!-- VALIDATION GLOBALE FACTURE -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on, attrs }">
            <div
              size="sm"
              class="block-icon mr-2"
              v-on="on"
              v-bind="attrs"
              @click.prevent="openConfirmationGlobalModal()"
              v-if="checkPermission('GAPTHVGF')"
            >
              <font-awesome-icon icon="check-double" class="file-medical" />
            </div>
          </template>
          <span>Validation globale des factures</span>
        </v-tooltip>
        <!--EXPORT EXCEL FACTURE-->
        <export-excel-facture
          @exportFactureXls="exportExcelFacture"
          :resetModalExportFacture="computedResetModalExportFacture"
          v-if="checkPermission('GAPTHXLS')"
        />
        <!-- EXPORT EXCEL FACTURE PDF -->
        <export-excel-facture-pdf
          @exportFacturePdf="exportFacturePdf"
          v-if="checkPermission('GAPTHTFP') && computedgetLotsToSend.length"
        />
        <!-- SEND EMAIL -->
        <div
          v-if="getMailLoadingVertigo"
          class="loading loader-mail-size mt-1 mr-2 ml-2"
        >
          <div class="spinner-border loadingFetch" role="status"></div>
        </div>
        <MailComponent
          v-if="
            computedgetLotsToSend.length &&
              !getMailLoadingVertigo &&
              checkPermission('GAPTHEE')
          "
          :lotsToSend="computedgetLotsToSend"
          :clientToSend="computedAcheuteurToSend"
        />
        <!-- FILTER -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on, attrs }">
            <div
              size="sm"
              v-on="on"
              v-bind="attrs"
              @click="openPopover"
              class="block-icon mr-2 button-height-unset"
            >
              <IconFiltre></IconFiltre>
            </div>
          </template>
          <span>Choisir un Filtre</span>
        </v-tooltip>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="350"
          origin="top right"
          content-class="popover-menu-sci"
        >
          <v-card>
            <v-divider class="line-hr-filter"></v-divider>
            <v-card-text class="body-card filter-content-popup">
              <v-form>
                <!-- Organsime -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GAPTHFO')"
                  :items="getListOrganismeObligee"
                  item-text="name"
                  item-value="id"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Organsime"
                  multiple
                  v-model="isLocalStorageOrganisme"
                  :loading="getLoadingDataFilterObligeeThProjects"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageOrganisme.length - 0 }} Organsime(s))
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageOrganisme.length - 0 }} Organsime(s))
                    </span>
                  </template>
                </v-autocomplete>

                <!-- Filiale Installateur -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GAPTHFFI')"
                  :items="getListInstallateurbligee"
                  v-model="isLocalStorageFiliale"
                  :loading="getLoadingDataFilterObligeeThProjects"
                  item-text="name"
                  item-value="id"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Filiale Installateur"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageFiliale.length - 0 }} Filiale(s)
                      Installateur(s))
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageFiliale.length - 0 }} Filiale(s)
                      Installateur(s))
                    </span>
                  </template>
                </v-autocomplete>

                <!-- Lot -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GAPTHFL')"
                  v-model="isLocalStorageLot"
                  :items="getListLotsOligee"
                  :loading="getLoadingDataFilterObligeeThProjects"
                  item-text="name"
                  item-value="id"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Lot"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageLot.length - 0 }} Lot(s))
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageLot.length - 0 }} Lot(s))
                    </span>
                  </template>
                </v-autocomplete>
              </v-form>
            </v-card-text>
            <v-card-actions class="popover-action-filter">
              <v-btn
                class="ma-2 capitalize-text"
                outlined
                color="#5E35B1"
                @click="validerPopover"
              >
                Appliquer
              </v-btn>
              <v-btn
                class="ma-2 ml-2 capitalize-text"
                text
                @click="closePopover"
              >
                Annuler
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div class="content-cadre-table-oblige ">
      <!-- BLOCK SELON FILTER SELECTIONNEE -->
      <div>
        <div class="block-filter-style" v-if="returnTableChips">
          <span class="phrase-style">Les filtres choisie sont: </span>
          <span class="item-filter-style" v-if="date_debut !== null"
            >Date début: {{ date_debut }}</span
          >
          <span class="item-filter-style" v-if="date_fin !== null"
            >Date fin: {{ date_fin }}</span
          >
          <span
            v-if="searchValue !== null && searchValue !== ''"
            class="item-filter-style"
            >Recherche:{{ searchValue }}</span
          >
        </div>
      </div>
      <!-- BLOCK SELON FILTER POPOVER SELECTIONNEE -->
      <v-row class="filter-Block-icon" v-if="returnTableFiltre">
        <!-- Organisme -->
        <v-col
          :cols="computedLengthFilter"
          v-if="filterOrganisme.length"
          class="space-table-filter-oblige-fac"
        >
          <span class="title-block-chip-filter mr-3">Organisme : </span>
          <v-sheet elevation="1" class="py-0 px-1 mr-3">
            <v-chip-group mandatory>
              <v-chip
                :key="v.name + 'organisme'"
                v-for="v in filterOrganisme"
                close
                @click:close="
                  remove(
                    v,
                    'filterOrganisme',
                    'handleFilter',
                    'isLocalStorageOrganisme'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ v.name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- Filiale Installateur -->
        <v-col
          :cols="computedLengthFilter"
          v-if="filterFiliale.length"
          class="space-table-filter-oblige-fac"
        >
          <span class="title-block-chip-filter">Filiale Installateur : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="a.name + 'filiale'"
                v-for="a in filterFiliale"
                close
                x-small
                @click:close="
                  remove(
                    v,
                    'filterFiliale',
                    'handleFilter',
                    'isLocalStorageFiliale'
                  )
                "
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ a.name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- LOT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="filterLot.length"
          class="space-table-filter-oblige-fac"
        >
          <span class="title-block-chip-filter">Lot : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="s + 'depot'"
                v-for="s in filterLot"
                close
                @click:close="
                  remove(s, 'filterLot', 'handleFilter', 'isLocalStorageLot')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
      </v-row>
      <!-- TABLE OBLIGE -->
      <div
        class="table-oblige"
        :class="{
          'padding-table': returnTableFiltre
        }"
      >
        <v-data-table
          v-model="selectedTable"
          :headers="computedFields2"
          :items="getAppeliPaiementThTableObligee"
          :items-per-page="per_page"
          @item-expand="getAppeliPaiementThTableObligee"
          :class="{
            'height-filter-chips-table':
              returnTableChips &&
              !returnTableFiltre &&
              (computedTotalProjectSelected == 0 ||
                (computedTotalProjectSelected > 0 && !showaTableSum)),
            'height-filter-chips-table-with-sum':
              returnTableChips &&
              !returnTableFiltre &&
              showaTableSum &&
              computedTotalProjectSelected > 0,
            'height-filter-table':
              returnTableFiltre &&
              !returnTableChips &&
              (computedTotalProjectSelected == 0 ||
                (computedTotalProjectSelected > 0 && !showaTableSum)),
            'height-filter-table-with-sum':
              returnTableFiltre &&
              !returnTableChips &&
              showaTableSum &&
              computedTotalProjectSelected > 0,
            'height-filter-tous':
              returnTableChips &&
              returnTableFiltre &&
              (computedTotalProjectSelected == 0 ||
                (computedTotalProjectSelected > 0 && !showaTableSum)),
            'height-filter-tous-with-sum':
              returnTableChips &&
              returnTableFiltre &&
              showaTableSum &&
              computedTotalProjectSelected > 0,
            'height-nornal-with-sum':
              showaTableSum &&
              computedTotalProjectSelected > 0 &&
              !returnTableChips &&
              !returnTableFiltre
          }"
          class="table-oblige-facture"
          hide-default-footer
          show-expand
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          :calculate-widths="false"
          :fixed-header="true"
          item-key="name"
          hide-action
          :no-data-text="
            getChargementAppelPaiementThObligee
              ? 'Chargement... Veuillez patienter'
              : `Il n'y a aucune donnée à afficher dans ce tableau.`
          "
        >
          <!-- HEADERS TABLE -->
          <template v-slot:[`header.check_all`]="{ header }">
            <td>
              <div>
                <font-awesome-icon
                  class="icon-plus align-icon"
                  :icon="['fas', 'square-plus']"
                  v-if="
                    computedHeaderVisibility(header.value) &&
                      $data['details_' + header.value] == false
                  "
                  @click="setVisibilityFieldsHeader(header)"
                />
                <font-awesome-icon
                  class="icon-plus align-icon"
                  :icon="['fas', 'square-minus']"
                  v-if="
                    computedHeaderVisibility(header.value) &&
                      $data['details_' + header.value] == true
                  "
                  @click="setVisibilityFieldsHeader(header)"
                />
              </div>
              <div v-if="header.value == 'check_all'">
                <v-checkbox
                  class="checked-groupe ml-2"
                  v-model="checkAll"
                  @change="checkAllFunction"
                  color="#704ad1"
                  :value="true"
                  :unchecked-value="false"
                  hide-details
                ></v-checkbox>
              </div>
              <span v-if="header.coef || header.statue">
                {{ header.text }}
                <span v-if="header.coef">
                  <column-header-with-tool-tip
                    v-if="
                      computedCheckedRows &&
                        computedCheckedRows.length &&
                        checkPermission('GAPTHMCPIPS')
                    "
                    :rowDetails="false"
                    :label="header.text"
                    :column="header.value"
                    :dataToUse="getAppeliPaiementThTableObligee"
                    :updateFunction="updateCoefObligee"
                    @reloadData="handleFilter"
                /></span>

                <span v-if="header.statue">
                  <validation-statue-obligee
                    v-if="
                      header.editable &&
                        computedCheckedRowsLots &&
                        computedCheckedRowsLots.length
                    "
                    :dataToUse="getAppeliPaiementThTableObligee"
                    @reloadData="handleFilter"
                    :type="header.value"/></span
              ></span>

              <span v-if="!header.statue && !header.coef">
                {{ header.text }}</span
              >
            </td>
          </template>
          <template v-slot:[`header.prime`]="{ header }">
            <td class="flex-div-annomalie">
              <button
                class="btn btn-anomalie mr-2"
                @click.prevent.stop="clickOpenHeaderIconPrime(header)"
              >
                <font-awesome-icon
                  class="icon-plus"
                  :icon="['fas', 'square-plus']"
                  color="#704ad1"
                  title="Afficher Facture(s)"
                  v-if="details_prime == false"
                />
                <font-awesome-icon
                  class="icon-plus"
                  :icon="['fas', 'square-minus']"
                  color="#704ad1"
                  title="Masquer Facture(s)"
                  v-if="details_prime == true"
                />
              </button>
              <span> {{ header.text }}</span>
            </td>
          </template>
          <template v-slot:[`header.missing_prime`]="{ header }">
            <td class="flex-div-annomalie">
              <button
                class="btn btn-anomalie mr-2"
                @click.prevent.stop="clickOpenHeaderIconMissingPrime(header)"
              >
                <font-awesome-icon
                  class="icon-plus"
                  :icon="['fas', 'square-plus']"
                  color="#704ad1"
                  title="Afficher Facture(s)"
                  v-if="header.details == false"
                />
                <font-awesome-icon
                  class="icon-plus"
                  :icon="['fas', 'square-minus']"
                  color="#704ad1"
                  title="Masquer Facture(s)"
                  v-if="header.details == true"
                />
              </button>
              <span> {{ header.text }}</span>
            </td>
          </template>
          <template v-slot:[`header.commission_gestion`]="{ header }">
            <td class="flex-div-annomalie mr-2">
              <button
                class="btn btn-anomalie"
                @click.prevent.stop="
                  clickOpenHeaderIconCommissionGestion(header)
                "
              >
                <font-awesome-icon
                  class="icon-plus"
                  :icon="['fas', 'square-plus']"
                  color="#704ad1"
                  title="Afficher Facture(s)"
                  v-if="header.details == false"
                />
                <font-awesome-icon
                  class="icon-plus"
                  :icon="['fas', 'square-minus']"
                  color="#704ad1"
                  title="Masquer Facture(s)"
                  v-if="header.details == true"
                />
              </button>
              <span> {{ header.text }}</span>
            </td>
          </template>
          <template v-slot:[`header.commission`]="{ header }">
            <td class="flex-div-annomalie mr-2">
              <button
                class="btn btn-anomalie"
                @click.prevent.stop="clickOpenHeaderIconCommission(header)"
              >
                <font-awesome-icon
                  class="icon-plus"
                  :icon="['fas', 'square-plus']"
                  color="#704ad1"
                  title="Afficher Facture(s)"
                  v-if="header.details == false"
                />
                <font-awesome-icon
                  class="icon-plus"
                  :icon="['fas', 'square-minus']"
                  color="#704ad1"
                  title="Masquer Facture(s)"
                  v-if="header.details == true"
                />
              </button>
              <span> {{ header.text }}</span>
            </td>
          </template>
          <template v-slot:[`header.surprime`]="{ header }">
            <td class="flex-div-annomalie">
              <button
                class="btn btn-anomalie mr-2"
                @click.prevent.stop="clickOpenHeaderIconSupprime(header)"
              >
                <font-awesome-icon
                  class="icon-plus"
                  :icon="['fas', 'square-plus']"
                  color="#704ad1"
                  title="Afficher Facture(s)"
                  v-if="header.details == false"
                />
                <font-awesome-icon
                  class="icon-plus"
                  :icon="['fas', 'square-minus']"
                  color="#704ad1"
                  title="Masquer Facture(s)"
                  v-if="header.details == true"
                />
              </button>
              <span> {{ header.text }}</span>
            </td>
          </template>
          <template v-slot:[`header.consolidee_energie_invest`]="{ header }">
            <td class="flex-div-annomalie">
              <button
                class="btn btn-anomalie mr-2"
                @click.prevent.stop="clickOpenHeaderIconEnergieInvest(header)"
              >
                <font-awesome-icon
                  class="icon-plus"
                  :icon="['fas', 'square-plus']"
                  color="#704ad1"
                  title="Afficher Facture(s)"
                  v-if="header.details == false"
                />
                <font-awesome-icon
                  class="icon-plus"
                  :icon="['fas', 'square-minus']"
                  color="#704ad1"
                  title="Masquer Facture(s)"
                  v-if="header.details == true"
                />
              </button>
              <span> {{ header.text }}</span>
            </td>
          </template>
          <!-- BODY TABLE -->
          <!-- CHECKED -->
          <template v-slot:[`item.check_all`]="{ item }">
            <td class="custom-cell sticky-header-1">
              <v-checkbox
                class="checked-groupe"
                v-model="item.check"
                @change="checkAllVerif(item)"
                color="#704ad1"
                :true-value="true"
                :false-value="false"
                hide-details
              ></v-checkbox>
            </td>
          </template>
          <!--  ICON EXPANDED -->
          <template v-slot:[`item.data-table-expand`]="{ item }">
            <td class="custom-cell sticky-header-2">
              <button
                class="btn btn-anomalie"
                v-if="item && item.lots && item.lots.length > 0"
                @click="showRowDetailsVueDefault(item)"
              >
                <font-awesome-icon
                  class="icon-plus"
                  :icon="['fas', 'square-plus']"
                  color="#704ad1"
                  title="Afficher Facture(s)"
                  v-if="item.showDetails == false"
                />
                <font-awesome-icon
                  class="icon-plus"
                  :icon="['fas', 'square-minus']"
                  color="#704ad1"
                  title="Masquer Facture(s)"
                  v-if="item.showDetails == true"
                />
              </button>
            </td>
          </template>
          <!-- NUMERO LOTS -->
          <template v-slot:[`item.name`]="{ item }">
            <td class="custom-cell sticky-header-3">
              <div class="d-flex justify-content-between ml-1 mr-1">
                <span class="ml-1"> {{ item.name }}</span>
                <font-awesome-icon
                  :icon="['fas', 'circle-check']"
                  class="icon-style-check-facture"
                  v-if="computedCHeckFiliale(item)"
                />
              </div>
            </td>
          </template>
          <!-- NOM PRENOM -->
          <template v-slot:[`item.nom`]="{}"> - </template>
          <!-- ORGANISME  -->
          <template v-slot:[`item.organisme`]="{}"> - </template>
          <!-- RESTE A CHARGE  -->
          <template v-slot:[`item.reste_a_charge`]="{}"> - </template>
          <!-- MONTANT TTC AVOIR -->
          <template v-slot:[`item.montant_ttc_avoir`]="{}"> - </template>
          <!-- MONTANT TOTAL TTC -->
          <template v-slot:[`item.montant_total_ttc`]="{}"> - </template>
          <!-- MONTANT PRIME CEE -->
          <template v-slot:[`item.montant_prime_cee`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.kwh_cumac`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.m_cumac`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.coef_prime_installateur`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.prime`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.prime_pu`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.prime_ttc`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.prime_tva`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.prime_num_facture`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.prime_installateur_avoir`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.missing_prime`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.missing_prime_pu`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.missing_prime_ttc`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.missing_prime_tva`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.missing_num_facture`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.comm_installateur_avoir`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.commission_gestion`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.commission_gestion_pu`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.commission_gestion_ttc`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.commission_gestion_tva`]="{}"> - </template>
          <!--  -->

          <template v-slot:[`item.commission_gestion_num_facture`]="{}">
            -
          </template>
          <!--  -->
          <template v-slot:[`item.commission_gestion_avoir`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.commission`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.commission_pu`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.commission_ttc`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.commission_tva`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.commission_num_facture`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.commission_avoir`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.surprime`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.surprime_pu`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.surprime_ttc`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.surprime_tva`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.surprime_num_facture`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.surprime_avoir`]="{}"> - </template>
          <!--  -->
          <template v-slot:[`item.consolidee_energie_invest`]="{}">
            -
          </template>
          <!--  -->
          <template v-slot:[`item.consolidee_energie_invest_pu`]="{}">
            -
          </template>
          <!--  -->
          <template v-slot:[`item.consolidee_energie_invest_ttc`]="{}">
            -
          </template>
          <!--  -->
          <template v-slot:[`item.consolidee_energie_invest_tva`]="{}">
            -
          </template>
          <!--  -->
          <template v-slot:[`item.consolidee_energie_invest_num_facture`]="{}">
            -
          </template>
          <template v-slot:[`item.consolidee_energie_invest_avoir`]="{}">
            -
          </template>
          <!--  -->
          <template v-slot:[`item.total`]="{}">
            <td class="td-total-coef-oblige">-</td>
          </template>

          <!-- <template v-slot:[`item.commission_statut`]="{}"> - </template> -->
          <!-- <template  v-slot:[`item.prime_statut`]="{}"> - </template> -->
          <!-- <template v-slot:[`item.surprime_statut`]="{}"> - </template> -->
          <!-- <template v-slot:[`item.missing_prime_statut`]="{}"> - </template> -->
          <!-- <template  v-slot:[`item.avoir`]="{}"> - </template> -->
          <!-- <template v-slot:[`item.missing_prime_av_statut`]="{}"> - </template> -->
          <!-- <template v-slot:[`item.missing_prime_av_num_facture`]="{}">
        -
      </template> -->
          <!-- <template v-slot:[`item.commission_gestion_statut`]="{}"> - </template> -->

          <!-- EXPANDED TABLE -->
          <template #expanded-item="{ headers, item }">
            <td
              class="td-expanded"
              :colspan="headers.length"
              v-if="
                item.lots && item.lots.length > 0 && item.showDetails == true
              "
            >
              <table :items="item.lots" class="table-expanded">
                <tbody>
                  <tr v-for="data in item.lots" :key="data.id">
                    <template
                      ><span
                        class="align-td-item border-botom background-week"
                        :class="{ ligneAnomalieObligee: data.error == true }"
                      >
                        <!-- CHECKED  -->
                        <td
                          class="td-expanded sticky-header-1"
                          style="width:40px  !important ; min-width:40px !important"
                        >
                          <v-checkbox
                            class="checked-expanded"
                            v-model="data.check"
                            @change="checkAllVerifDetails(item, data)"
                            color="#704ad1"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                          ></v-checkbox>
                        </td>
                        <!-- ICON EXPANDED -->
                        <td
                          class="td-expanded sticky-header-2 centre-col-td"
                          style="width:40px  !important ; min-width:40px !important"
                        >
                          <button
                            class="btn btn-anomalie"
                            @click="data.showDetails = !data.showDetails"
                            :disabled="data.projects.length > 0 ? false : true"
                          >
                            <font-awesome-icon
                              class="icon-plus-double icon-color-cdg"
                              icon="angle-right"
                              v-if="data.showDetails == false"
                            />
                            <font-awesome-icon
                              class="icon-plus-double icon-color-cdg"
                              icon="angle-down"
                              v-if="data.showDetails == true"
                            />
                          </button>
                        </td>
                        <!-- NUMERO LOTS -->
                        <td
                          class="td-expanded sticky-header-3 centre-col-td-lot"
                        >
                          <div class="flex-div">
                            <PopoverObligeFacture
                              content="num_lot"
                              :data="data"
                            ></PopoverObligeFacture>
                            <span> {{ data.name }}</span>
                          </div>
                        </td>
                        <!-- NOM PRENOM -->
                        <td class="td-expanded centre-col-td ">
                          {{ '-' }}
                        </td>
                        <!-- ORGANISME -->
                        <td class="td-expanded centre-col-td">
                          {{ '-' }}
                        </td>
                        <td class="td-expanded centre-col-td">
                          {{ data.reste_a_charge ? data.reste_a_charge : '-' }}
                        </td>
                        <td class="td-expanded centre-col-td">
                          {{ data.ttc_avoir ? data.ttc_avoir : '-' }}
                        </td>
                        <td class="td-expanded centre-col-td">
                          {{
                            data.montant_total_ttc
                              ? data.montant_total_ttc
                              : '-'
                          }}
                        </td>
                        <td class="td-expanded centre-col-td">
                          {{ data.cee ? data.cee : '-' }}
                        </td>
                        <td class="td-expanded centre-col-td">
                          {{ data.kwh ? data.kwh : '-' }}
                        </td>
                        <td class="td-expanded centre-col-td">
                          {{ data.mega ? data.mega : '-' }}
                        </td>
                        <td class="td-expanded centre-col-td">-</td>
                        <!-- PRIME -->
                        <td
                          class="td-expanded centre-col-td"
                          v-if="checkPermission('GAPTHACP')"
                        >
                          <div class="flex-div">
                            <PopoverObligeFacture
                              content="prime_ht"
                              :data="data"
                              v-if="
                                data &&
                                  data.prime_factures &&
                                  data.prime_factures.length
                              "
                            ></PopoverObligeFacture>

                            <span
                              class="text-avoir-color ml-1"
                              :class="{ 'red-color': data.warning }"
                            >
                              {{ data.prime_ht }} €
                            </span>
                            <template-obligee
                              v-if="
                                !data.error_vendeur &&
                                  !data.error_acheteur &&
                                  !data.error_group
                              "
                              type="prime"
                              type2="non-avoir"
                              :iconTable="true"
                              :show_generate_facture_file_code="
                                !data.error &&
                                  data.prime_statut != 'Payé' &&
                                  checkPermission('GAPTHVFP')
                              "
                              :item="data"
                              :updateFunction="
                                uploadObligeeTemplateInServieVisionFiliale
                              "
                              :getTemplateFunction="
                                getObligeeTemplateAndFiledsDataToUpdate
                              "
                              :refereshTemplateFunction="refreshFactureDossier"
                              :download="data.prime_factures.map(i => i.id)"
                              :showDownloadButton="
                                checkPermission('GAPTHTFPR') &&
                                  data.prime_factures.length
                              "
                              :value="data.prime_ht"
                              :can_reload="data.can_reload"
                              :downloadFactureApi="downloadMultiAvoirPdfs"
                            />
                          </div>
                        </td>
                        <template v-if="computedShowSubRow('prime')">
                          <td class="td-expanded centre-col-td">-</td>
                          <td class="td-expanded centre-col-td">
                            {{ data.prime_ttc }} €
                          </td>
                          <td class="td-expanded centre-col-td">
                            {{ data.prime_tva }} €
                          </td>
                          <td class="td-expanded centre-col-td">
                            {{ data.prime_num_facture }}
                          </td>
                        </template>
                        <td
                          class="td-expanded centre-col-td"
                          v-if="checkPermission('GAPTHACAP')"
                        >
                          <div class="flex-div">
                            <PopoverObligeFacture
                              content="prime_avoir"
                              :data="data"
                              v-if="
                                data &&
                                  data.prime_factures &&
                                  data.prime_factures.length
                              "
                            ></PopoverObligeFacture>

                            <span
                              class="text-avoir-color ml-1"
                              :class="{ 'red-color': data.warning }"
                            >
                              {{ data.prime_avoir }} €
                            </span>
                          </div>
                        </td>
                        <!-- Missing prime -->
                        <td
                          class="td-expanded centre-col-td"
                          v-if="checkPermission('GAPTHACI')"
                        >
                          <div class="flex-div">
                            <PopoverObligeFacture
                              content="missing_prime_ht"
                              :data="data"
                              v-if="
                                data &&
                                  data.missing_prime_factures &&
                                  data.missing_prime_factures.length
                              "
                            ></PopoverObligeFacture>
                            <span
                              class="text-avoir-color ml-1"
                              :class="{ 'red-color': data.warning }"
                            >
                              {{ data.missing_prime_ht }} €
                            </span>
                            <template-obligee
                              v-if="
                                !data.error_vendeur &&
                                  !data.error_acheteur &&
                                  !data.error_group
                              "
                              type="missing_prime"
                              type2="non-avoir"
                              :iconTable="true"
                              :show_generate_facture_file_code="
                                !data.error &&
                                  data.missing_prime_statut != 'Payé' &&
                                  checkPermission('GAPTHVFI')
                              "
                              :item="data"
                              :updateFunction="
                                uploadObligeeTemplateInServieVisionFiliale
                              "
                              :getTemplateFunction="
                                getObligeeTemplateAndFiledsDataToUpdate
                              "
                              :refereshTemplateFunction="refreshFactureDossier"
                              :download="
                                data.missing_prime_factures.map(i => i.id)
                              "
                              :showDownloadButton="
                                checkPermission('GAPTHTFIR') &&
                                  data.missing_prime_factures.length
                              "
                              :value="data.missing_prime_ht"
                              :can_reload="data.can_reload"
                              :downloadFactureApi="downloadMultiAvoirPdfs"
                            />
                          </div>
                        </td>
                        <template v-if="computedShowSubRow('missing_prime')">
                          <td class="td-expanded centre-col-td">-</td>

                          <td class="td-expanded centre-col-td">
                            <span :class="{ 'red-color': data.warning }">
                              {{ data.missing_prime_ttc }} €</span
                            >
                          </td>
                          <td class="td-expanded centre-col-td">
                            <span :class="{ 'red-color': data.warning }">
                              {{ data.missing_prime_tva }} €</span
                            >
                          </td>
                          <td class="td-expanded centre-col-td">
                            {{ data.missing_prime_num_facture }}
                          </td>
                        </template>
                        <td
                          class="td-expanded centre-col-td"
                          v-if="checkPermission('GAPTHACAI')"
                        >
                          <div class="flex-div">
                            <PopoverObligeFacture
                              content="missing_prime_avoir"
                              :data="data"
                              v-if="
                                data &&
                                  data.missing_prime_factures &&
                                  data.missing_prime_factures.length
                              "
                            ></PopoverObligeFacture>

                            <span
                              class="text-avoir-color ml-1"
                              :class="{ 'red-color': data.warning }"
                            >
                              {{ data.missing_prime_avoir }} €
                            </span>
                          </div>
                        </td>
                        <!-- commission gestion-->
                        <td
                          class="td-expanded centre-col-td"
                          v-if="checkPermission('GAPTHACCG')"
                        >
                          <div class="flex-div">
                            <PopoverObligeFacture
                              content="commission_gestion_ht"
                              :data="data"
                              v-if="
                                data &&
                                  data.commission_gestion_factures &&
                                  data.commission_gestion_factures.length
                              "
                            ></PopoverObligeFacture>
                            <span
                              class="text-avoir-color ml-1"
                              :class="{ 'red-color': data.warning }"
                            >
                              {{ data.commission_gestion_ht }} €
                            </span>
                            <template-obligee
                              v-if="
                                !data.error_vendeur &&
                                  !data.error_acheteur &&
                                  !data.error_group
                              "
                              type="commission_gestion"
                              type2="non-avoir"
                              :iconTable="true"
                              :show_generate_facture_file_code="
                                !data.error &&
                                  data.commission_gestion_statut != 'Payé' &&
                                  checkPermission('GAPTHVFCG') &&
                                  data.can_facture_commission_gestion
                              "
                              :item="data"
                              :updateFunction="
                                uploadObligeeTemplateInServieVisionFiliale
                              "
                              :getTemplateFunction="
                                getObligeeTemplateAndFiledsDataToUpdate
                              "
                              :refereshTemplateFunction="refreshFactureDossier"
                              :download="
                                data.commission_gestion_factures.map(i => i.id)
                              "
                              :showDownloadButton="
                                checkPermission('GAPTHTFCG') &&
                                  data.commission_gestion_factures.length
                              "
                              :value="data.commission_gestion_ht"
                              :can_reload="data.can_reload"
                              :downloadFactureApi="downloadMultiAvoirPdfs"
                            />
                          </div>
                        </td>
                        <template
                          v-if="computedShowSubRow('commission_gestion')"
                        >
                          <td class="td-expanded centre-col-td">-</td>
                          <td class="td-expanded centre-col-td">
                            {{ data.commission_gestion_ttc }} €
                          </td>
                          <td class="td-expanded centre-col-td">
                            {{ data.commission_gestion_tva }} €
                          </td>
                          <td class="td-expanded centre-col-td">
                            {{ data.commission_gestion_num_facture }}
                          </td>
                        </template>
                        <td
                          class="td-expanded centre-col-td"
                          v-if="checkPermission('GAPTHACACG')"
                        >
                          <div class="flex-div">
                            <PopoverObligeFacture
                              content="commission_gestion_avoir"
                              :data="data"
                              v-if="
                                data &&
                                  data.commission_gestion_factures &&
                                  data.commission_gestion_factures.length
                              "
                            ></PopoverObligeFacture>

                            <span
                              class="text-avoir-color ml-1"
                              :class="{ 'red-color': data.warning }"
                            >
                              {{ data.commission_gestion_avoir }} €
                            </span>
                          </div>
                        </td>
                        <!-- Commission -->
                        <td
                          class="td-expanded centre-col-td"
                          v-if="checkPermission('GAPTHACC')"
                        >
                          <div class="flex-div">
                            <PopoverObligeFacture
                              content="commission_ht"
                              :data="data"
                              v-if="
                                data &&
                                  data.commission_factures &&
                                  data.commission_factures.length
                              "
                            ></PopoverObligeFacture>

                            <span
                              class="text-avoir-color ml-1"
                              :class="{ 'red-color': data.warning }"
                            >
                              {{ data.commission_ht }} €
                            </span>
                            <template-obligee
                              v-if="
                                !data.error_vendeur &&
                                  !data.error_acheteur &&
                                  !data.error_group
                              "
                              type="commission"
                              type2="non-avoir"
                              :iconTable="true"
                              :show_generate_facture_file_code="
                                !data.error &&
                                  data.commission_statut != 'Payé' &&
                                  checkPermission('GAPTHVFC') &&
                                  data.can_facture_commission
                              "
                              :item="data"
                              :updateFunction="
                                uploadObligeeTemplateInServieVisionFiliale
                              "
                              :getTemplateFunction="
                                getObligeeTemplateAndFiledsDataToUpdate
                              "
                              :refereshTemplateFunction="refreshFactureDossier"
                              :download="
                                data.commission_factures.map(i => i.id)
                              "
                              :showDownloadButton="
                                checkPermission('GAPTHTFCR') &&
                                  data.commission_factures.length
                              "
                              :value="data.commission_ht"
                              :can_reload="data.can_reload"
                              :downloadFactureApi="downloadMultiAvoirPdfs"
                            />
                          </div>
                        </td>
                        <template v-if="computedShowSubRow('commission')">
                          <td class="td-expanded centre-col-td">-</td>
                          <td class="td-expanded centre-col-td">
                            {{ data.commission_ttc }} €
                          </td>
                          <td class="td-expanded centre-col-td">
                            {{ data.commission_tva }} €
                          </td>
                          <td class="td-expanded centre-col-td">
                            {{ data.commission_num_facture }}
                          </td>
                        </template>
                        <td
                          class="td-expanded centre-col-td"
                          v-if="checkPermission('GAPTHACAC')"
                        >
                          <div class="flex-div">
                            <PopoverObligeFacture
                              content="commission_avoir"
                              :data="data"
                              v-if="
                                data &&
                                  data.commission_factures &&
                                  data.commission_factures.length
                              "
                            ></PopoverObligeFacture>

                            <span
                              class="text-avoir-color ml-1"
                              :class="{ 'red-color': data.warning }"
                            >
                              {{ data.commission_avoir }} €
                            </span>
                          </div>
                        </td>
                        <!-- Surprime -->
                        <td
                          class="td-expanded centre-col-td"
                          v-if="checkPermission('GAPTHACS')"
                        >
                          <div class="flex-div">
                            <PopoverObligeFacture
                              content="surprime_ht"
                              :data="data"
                              v-if="
                                data &&
                                  data.surprime_factures &&
                                  data.surprime_factures.length
                              "
                            ></PopoverObligeFacture>

                            <span
                              class="text-avoir-color ml-1"
                              :class="{ 'red-color': data.warning }"
                            >
                              {{ data.surprime_ht }} €
                            </span>
                            <template-obligee
                              v-if="
                                !data.error_vendeur &&
                                  !data.error_acheteur &&
                                  !data.error_group
                              "
                              type="surprime"
                              type2="non-avoir"
                              :iconTable="true"
                              :show_generate_facture_file_code="
                                !data.error &&
                                  data.surprime_statut != 'Payé' &&
                                  checkPermission('GAPTHVFS') &&
                                  data.can_facture_surprime
                              "
                              :item="data"
                              :updateFunction="
                                uploadObligeeTemplateInServieVisionFiliale
                              "
                              :getTemplateFunction="
                                getObligeeTemplateAndFiledsDataToUpdate
                              "
                              :refereshTemplateFunction="refreshFactureDossier"
                              :download="data.surprime_factures.map(i => i.id)"
                              :showDownloadButton="
                                checkPermission('GAPTHTFSR') &&
                                  data.surprime_factures.length
                              "
                              :value="data.surprime_ht"
                              :can_reload="data.can_reload"
                              :downloadFactureApi="downloadMultiAvoirPdfs"
                            />
                          </div>
                        </td>
                        <template v-if="computedShowSubRow('surprime')">
                          <td class="td-expanded centre-col-td">-</td>
                          <td class="td-expanded centre-col-td">
                            {{ data.surprime_ttc }} €
                          </td>
                          <td class="td-expanded centre-col-td">
                            {{ data.surprime_tva }} €
                          </td>
                          <td class="td-expanded centre-col-td">
                            {{ data.surprime_num_facture }}
                          </td>
                        </template>
                        <td
                          class="td-expanded centre-col-td"
                          v-if="checkPermission('GAPTHACAS')"
                        >
                          <div class="flex-div">
                            <PopoverObligeFacture
                              content="surprime_avoir"
                              :data="data"
                              v-if="
                                data &&
                                  data.surprime_factures &&
                                  data.surprime_factures.length
                              "
                            ></PopoverObligeFacture>

                            <span
                              class="text-avoir-color ml-1"
                              :class="{ 'red-color': data.warning }"
                            >
                              {{ data.surprime_avoir }} €
                            </span>
                          </div>
                        </td>
                        <!-- Factures consolide -->
                        <td
                          class="td-expanded centre-col-td"
                          v-if="checkPermission('GAPTHACFCEI')"
                        >
                          <div class="flex-div">
                            <PopoverObligeFacture
                              content="consolidee_energie_invest_ht"
                              :data="data"
                              v-if="
                                data &&
                                  data.consolidee_energie_invest_factures &&
                                  data.consolidee_energie_invest_factures.length
                              "
                            ></PopoverObligeFacture>

                            <span
                              class="text-avoir-color ml-1"
                              :class="{ 'red-color': data.warning }"
                            >
                              {{ data.consolidee_energie_invest_ht }} €
                            </span>
                            <template-obligee
                              v-if="
                                !data.error_vendeur &&
                                  !data.error_acheteur &&
                                  !data.error_group
                              "
                              type="consolidee_energie_invest"
                              type2="non-avoir"
                              :iconTable="true"
                              :show_generate_facture_file_code="
                                !data.error &&
                                  data.consolidee_energie_invest_statut !=
                                    'Payé' &&
                                  checkPermission('GAPTHVFCEI') &&
                                  data.can_facture_consolidee_energie_invest
                              "
                              :item="data"
                              :updateFunction="
                                uploadObligeeTemplateInServieVisionFiliale
                              "
                              :getTemplateFunction="
                                getObligeeTemplateAndFiledsDataToUpdate
                              "
                              :refereshTemplateFunction="refreshFactureDossier"
                              :download="
                                data.consolidee_energie_invest_factures.map(
                                  i => i.id
                                )
                              "
                              :showDownloadButton="
                                checkPermission('GAPTHTPFACEI') &&
                                  data.consolidee_energie_invest_factures.length
                              "
                              :value="data.consolidee_energie_invest_ht"
                              :can_reload="data.can_reload"
                              :downloadFactureApi="downloadMultiAvoirPdfs"
                            />
                          </div>
                        </td>
                        <template
                          v-if="computedShowSubRow('consolidee_energie_invest')"
                        >
                          <td class="td-expanded centre-col-td">-</td>
                          <td class="td-expanded centre-col-td">
                            {{ data.consolidee_energie_invest_ttc }} €
                          </td>
                          <td class="td-expanded centre-col-td">
                            {{ data.consolidee_energie_invest_tva }} €
                          </td>
                          <td class="td-expanded centre-col-td">
                            {{ data.consolidee_energie_invest_num_facture }}
                          </td>
                        </template>
                        <td
                          class="td-expanded centre-col-td"
                          v-if="checkPermission('GAPTHACACEI')"
                        >
                          <div class="flex-div">
                            <PopoverObligeFacture
                              content="consolidee_energie_invest_avoir"
                              :data="data"
                              v-if="
                                data &&
                                  data.consolidee_energie_invest_factures &&
                                  data.consolidee_energie_invest_factures.length
                              "
                            ></PopoverObligeFacture>

                            <span
                              class="text-avoir-color ml-1"
                              :class="{ 'red-color': data.warning }"
                            >
                              {{ data.consolidee_energie_invest_avoir }} €
                            </span>
                          </div>
                        </td>
                        <td
                          class="td-expanded centre-col-td td-total-coef-oblige"
                        >
                          <!-- {{ computedTotalDepot(data) }} € -->
                          -
                        </td>
                      </span>
                    </template>
                    <template v-if="data.showDetails == true">
                      <span
                        v-for="(itemD, index) in data.projects"
                        :key="'depot' + itemD + index"
                        class="align-icon-flech-item border-botom background-week"
                        :class="{ ligneAnomalieObligee: itemD.error == true }"
                      >
                        <td
                          class="td-expanded sticky-header-1"
                          style="width:40px  !important ; min-width:40px !important"
                        >
                          {{ itemD.error }}
                          <v-checkbox
                            class="checked-expanded rows-check"
                            v-model="itemD.check"
                            @change="
                              checkAllVerifDetailsDepot(
                                item,
                                data,
                                itemD,
                                index
                              )
                            "
                            color="#704ad1"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                          ></v-checkbox>
                        </td>
                        <td
                          class="td-expanded sticky-header-2"
                          style="width:40px  !important ; min-width:40px !important"
                        >
                          <!-- <div class="block-icon-table-oblige-facture">
                          <font-awesome-icon icon="info" class="" />
                        </div> -->
                        </td>
                        <td class="td-expanded sticky-header-3">
                          <font-awesome-icon
                            icon="lock-open"
                            class="mr-1 icon-color-cdg"
                            v-if="itemD.montant_min_lock == false"
                          />
                          <font-awesome-icon
                            icon="lock"
                            class="mr-1 icon-color-cdg"
                            v-if="itemD.montant_min_lock == true"
                          />
                          {{ itemD.numero_dossier }}
                        </td>
                        <td class="td-expanded">
                          {{ itemD.nom1 }} {{ itemD.prenom1 }}
                        </td>
                        <td class="td-expanded">
                          {{ itemD.organisme }}
                        </td>
                        <td class="td-expanded">
                          {{ itemD.reste_a_charge }}
                        </td>
                        <td class="td-expanded">
                          {{ itemD.montant_ttc_avoir }}
                        </td>
                        <td class="td-expanded">
                          {{ itemD.montant_total_ttc }} €
                        </td>
                        <td class="td-expanded">
                          <EditableInputSuiviTh
                            :editable="checkPermission('GAPTHMMPC')"
                            champName="montant_prime_cee"
                            :item="itemD"
                            :value="itemD.montant_prime_cee"
                            type="text"
                            :updateFunction="updateSuiviPaiementDataTh"
                            valueToShow="montant_prime_cee"
                            :showIconEdit="checkPermission('GAPTHMMPC')"
                            :data="data"
                          />
                        </td>
                        <td class="td-expanded">
                          <EditableInputSuiviTh
                            :editable="checkPermission('GAPTHMKC')"
                            champName="kwh_cumac"
                            :item="itemD"
                            :value="itemD.kwh_cumac"
                            type="text"
                            :updateFunction="updateSuiviPaiementDataTh"
                            valueToShow="kwh_cumac"
                            :showIconEdit="checkPermission('GAPTHMKC')"
                            :data="data"
                          />
                        </td>
                        <td class="td-expanded">{{ itemD.m_cumac }}</td>
                        <td class="td-expanded">
                          <EditableInputSuiviTh
                            :editable="checkPermission('GAPTHMCPIPU')"
                            champName="coef_prime_installateur"
                            :item="itemD"
                            :value="itemD.coef_prime_installateur"
                            type="text"
                            :updateFunction="updateSuiviPaiementDataTh"
                            valueToShow="coef_prime_installateur"
                            :showIconEdit="checkPermission('GAPTHMCPIPU')"
                            :data="data"
                          />
                        </td>
                        <!-- prime  -->
                        <td
                          class="td-expanded"
                          v-if="checkPermission('GAPTHACP')"
                        >
                          {{ itemD.prime_ht }}
                        </td>
                        <template v-if="computedShowSubRow('prime')">
                          <td class="td-expanded">
                            <template v-if="itemD.reste_a_charge > 1">
                              <EditableInputSuiviTh
                                :editable="computedCantUpdate"
                                champName="prime_pu"
                                :item="itemD"
                                :value="itemD.prime_pu"
                                type="text"
                                :updateFunction="updateSuiviPaiementDataTh"
                                valueToShow="prime_pu"
                                :showIconEdit="true"
                                :data="data"
                              />
                            </template>
                            <template v-else> {{ itemD.prime_pu }} </template>
                          </td>
                          <td class="td-expanded">{{ itemD.prime_ttc }} €</td>
                          <td class="td-expanded">{{ itemD.prime_tva }} €</td>
                          <!-- <td  class="td-expanded">-</td> -->
                          <td class="td-expanded">-</td>
                        </template>
                        <td
                          class="td-expanded"
                          v-if="checkPermission('GAPTHACAP')"
                        >
                          -
                        </td>
                        <!-- missing prime  -->
                        <td
                          class="td-expanded"
                          v-if="checkPermission('GAPTHACI')"
                        >
                          <span :class="{ 'red-color': itemD.warning }">
                            {{ itemD.missing_prime_ht }} €</span
                          >
                        </td>
                        <template v-if="computedShowSubRow('missing_prime')">
                          <td class="td-expanded">
                            {{ itemD.missing_prime_pu }}
                          </td>

                          <td class="td-expanded">
                            <span :class="{ 'red-color': itemD.warning }">
                              {{ itemD.missing_prime_ttc }} €</span
                            >
                          </td>

                          <td class="td-expanded">
                            <span :class="{ 'red-color': itemD.warning }">
                              {{ itemD.missing_prime_tva }} €</span
                            >
                          </td>

                          <!-- <td  class="td-expanded">-</td> -->
                          <td class="td-expanded">-</td>
                        </template>
                        <td
                          class="td-expanded"
                          v-if="checkPermission('GAPTHACAI')"
                        >
                          -
                        </td>
                        <!-- commission gestion -->
                        <td
                          class="td-expanded"
                          v-if="checkPermission('GAPTHACCG')"
                        >
                          {{ itemD.commission_gestion_ht }} €
                        </td>
                        <template
                          v-if="computedShowSubRow('commission_gestion')"
                        >
                          <td class="td-expanded">
                            <EditableInputSuiviTh
                              :editable="
                                computedCantUpdate &&
                                  item.commission_gestion_statut != 'Payé'
                              "
                              champName="commission_gestion_pu"
                              :item="itemD"
                              :value="itemD.commission_gestion_pu"
                              type="text"
                              :updateFunction="updateSuiviPaiementDataTh"
                              valueToShow="commission_gestion_pu"
                              :showIconEdit="true"
                              :data="data"
                            />
                          </td>
                          <td class="td-expanded">
                            {{ itemD.commission_gestion_ttc }} €
                          </td>
                          <td class="td-expanded">
                            {{ itemD.commission_gestion_tva }} €
                          </td>
                          <!-- <td  class="td-expanded">-</td> -->
                          <td class="td-expanded td-total-coef-oblige">-</td>
                        </template>
                        <td
                          class="td-expanded"
                          v-if="checkPermission('GAPTHACACG')"
                        >
                          -
                        </td>
                        <!-- commision -->
                        <td
                          class="td-expanded"
                          v-if="checkPermission('GAPTHACCG')"
                        >
                          {{ itemD.commission_ht }} €
                        </td>
                        <template v-if="computedShowSubRow('commission')">
                          <td class="td-expanded">
                            <EditableInputSuiviTh
                              :editable="
                                computedCantUpdate &&
                                  item.commission_statut != 'Payé'
                              "
                              champName="commission_pu"
                              :item="itemD"
                              :value="itemD.commission_pu"
                              type="text"
                              :updateFunction="updateSuiviPaiementDataTh"
                              valueToShow="commission_pu"
                              :showIconEdit="true"
                              :data="data"
                            />
                          </td>
                          <td class="td-expanded">
                            {{ itemD.commission_ttc }} €
                          </td>
                          <td class="td-expanded">
                            {{ itemD.commission_tva }} €
                          </td>
                          <!-- <td class="item-details-style b">-</td> -->
                          <td class="td-expanded">-</td>
                        </template>
                        <td
                          class="td-expanded"
                          v-if="checkPermission('GAPTHACAC')"
                        >
                          -
                        </td>
                        <!-- surprime -->
                        <td
                          class="td-expanded"
                          v-if="checkPermission('GAPTHACS')"
                        >
                          {{ itemD.surprime_ht }} €
                        </td>
                        <template v-if="computedShowSubRow('surprime')">
                          <td class="td-expanded">
                            <EditableInputSuiviTh
                              :editable="
                                computedCantUpdate &&
                                  item.surprime_statut != 'Payé'
                              "
                              champName="surprime_pu"
                              :item="itemD"
                              :value="itemD.surprime_pu"
                              type="text"
                              :updateFunction="updateSuiviPaiementDataTh"
                              valueToShow="surprime_pu"
                              :showIconEdit="true"
                              :data="data"
                            />
                          </td>
                          <td class="td-expanded">
                            {{ itemD.surprime_ttc }} €
                          </td>
                          <td class="td-expanded">
                            {{ itemD.surprime_tva }} €
                          </td>
                          <!-- <td  class="td-expanded">-</td> -->
                          <td class="td-expanded">-</td>
                        </template>
                        <td
                          class="td-expanded"
                          v-if="checkPermission('GAPTHACAS')"
                        >
                          -
                        </td>

                        <!-- consolide energie invest -->
                        <td
                          class="td-expanded"
                          v-if="checkPermission('GAPTHACFCEI')"
                        >
                          {{ itemD.consolidee_energie_invest_ht }} €
                        </td>
                        <template
                          v-if="computedShowSubRow('consolidee_energie_invest')"
                        >
                          <td class="td-expanded">
                            <EditableInputSuiviTh
                              :editable="
                                computedCantUpdate &&
                                  item.consolidee_energie_invest_statut !=
                                    'Payé'
                              "
                              champName="consolidee_energie_invest_pu"
                              :item="itemD"
                              :value="itemD.consolidee_energie_invest_pu"
                              type="text"
                              :updateFunction="updateSuiviPaiementDataTh"
                              valueToShow="consolidee_energie_invest_pu"
                              :showIconEdit="true"
                              :data="data"
                            />
                          </td>
                          <td class="td-expanded">
                            {{ itemD.consolidee_energie_invest_ttc }} €
                          </td>
                          <td class="td-expanded">
                            {{ itemD.consolidee_energie_invest_tva }} €
                          </td>
                          <!-- <td  class="td-expanded">-</td> -->
                          <td class="td-expanded">-</td>
                        </template>
                        <td
                          class="td-expanded"
                          v-if="checkPermission('GAPTHACACEI')"
                        >
                          -
                        </td>
                        <td class="td-expanded td-total-coef-oblige">
                          <!-- {{ computedTotal(itemD) }} € -->
                          {{ itemD.total_coef }}
                        </td>
                      </span>
                    </template>
                  </tr>
                </tbody>
              </table>
            </td>
          </template>
        </v-data-table>
        <div
          class="d-flex justify-center mt-2 mb-2"
          v-if="computedTotalProjectSelected > 0"
        >
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="btn-with-icon-style mr-2"
                v-on="on"
                v-bind="attrs"
                @click.stop="showaTableSum = !showaTableSum"
              >
                <font-awesome-icon
                  :icon="showaTableSum ? 'arrow-down' : 'arrow-up'"
                />
              </div>
            </template>
            <span>{{
              showaTableSum
                ? 'Masquer le tableau de sommation'
                : 'Afficher le tableau de sommation'
            }}</span>
          </v-tooltip>
        </div>

        <div v-if="showaTableSum && computedTotalProjectSelected > 0">
          <v-data-table
            :headers="headersSum"
            :items="computedData"
            class="table-sommation-oblige"
            :calculate-widths="false"
            hide-default-footer
            disable-pagination
          >
            <!-- noUnite -->
          </v-data-table>
        </div>
      </div>
      <!--  -->
    </div>
    <!-- FOOTER PAGINATION PERPAGE -->
    <div class="footer-style-table">
      <div class="text-center pagination-table">
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify">
        <v-select
          v-model="per_page"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetX: true
          }"
          color="#704ad1"
          item-color="#704ad1"
        ></v-select>
      </div>
    </div>

    <!-- DOSSIER SANS ORGANISME -->
    <b-modal
      ref="missingOrganisme"
      id="missingOrganisme"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Dossier sans organisme'"
      @hidden="hideModal('missingOrganisme')"
      modal-class="modal-extaction-bdd-frais"
    >
      <ul>
        <li
          v-for="item in missingOrganismeInObligeeFile"
          :key="'org' + item"
          class="text-missing-organisme-obligee"
        >
          {{ item }}
        </li>
      </ul>
    </b-modal>
    <!-- CONFIRMATION FACTURE -->
    <v-dialog
      v-model="confirmValidFcature"
      max-width="750px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-header-vue-oblige"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            Confirmation validation des factures</v-label
          >
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('confirmValidFcature')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card">
          <div
            v-if="loader_list_facture"
            class="loading-custom-reglement-block"
          >
            <Half-circle-spinner
              :animation-duration="1000"
              :size="50"
              :color="'#704ad1'"
              class="loeder"
            />
          </div>
          <div v-if="!loader_list_facture" class="mt-3">
            <v-label class="title-tabel-global mt-2 mb-0"
              >Type de facture :</v-label
            >
            <!-- TYPE FACTURE -->
            <v-checkbox
              label="Tous"
              color="#704ad1"
              hide-details
              v-model="checkAllType"
              @change="changeAllCheckTypes"
            ></v-checkbox>

            <div
              v-if="getTypeListFactureObligee.length"
              class="confirm-fac-check-oblige"
            >
              <div
                v-for="(option, index) in getTypeListFactureObligee"
                :key="index"
                class="mr-3"
              >
                <v-checkbox
                  color="#704ad1"
                  hide-details
                  v-model="type"
                  @change="
                    type.length == getTypeListFactureObligee.length
                      ? (checkAllType = true)
                      : (checkAllType = false)
                  "
                  :value="option.value"
                  :label="option.text"
                >
                </v-checkbox>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div
            v-if="getErrorAppeliPaiementThObligee"
            class="message-error-modal"
          >
            <ul
              v-if="Array.isArray(getErrorAppeliPaiementThObligee)"
              class="mb-0"
            >
              <li
                v-for="(e, index) in getErrorAppeliPaiementThObligee"
                :key="index"
              >
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorAppeliPaiementThObligee }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            :loading="loading"
            @click="validationGlobalFactureObligee"
            :class="{ loader: loading }"
          >
            Générer les factures
          </v-btn>
          <v-btn text @click.prevent="hideModal('confirmValidFcature')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Gestions-des-appels-à-paiement-Obligé',

  data() {
    return {
      expanded: [],
      singleExpand: false,
      selectedTable: [],
      confirmValidFcature: false,
      insertionXls: false,
      isDatePickerDebutVisible: false,
      isDatePickerFinVisible: false,
      showPopover: false,
      error: null,
      searchValue: null,
      showaTableSum: false,
      details_prime: false,
      details_commission: false,
      details_missing_prime: false,
      details_surprime: false,
      details_commission_gestion: false,
      details_consolidee_energie_invest: false,

      clickCount_prime: 0,
      clickCount_commission: 0,
      clickCount_missing_prime: 0,
      clickCount_surprime: 0,
      clickCount_commission_gestion: 0,
      clickCount_consolidee_energie_invest: 0,

      loadingUpload: false,
      errorUpload: null,
      file: null,
      loader_list_facture: false,
      // optionsSelectStatut: [{ full_name: 'a paye' }, { full_name: 'paye' }],
      optionsSelectStatut: [
        { value: 'A payer', full_name: 'A payer' },
        { value: 'Payé', full_name: 'Payée' }
        // { value: 'Annulée', full_name: 'Annulée' }
      ],
      date_debut: null,
      date_fin: null,
      page: 1,
      per_page: 20,
      filterFiliale: [],
      filterLot: [],
      filterOrganisme: [],
      templateAvoir: null,
      show: true,
      checkAllType: true,
      type: [],
      checkAll: false,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      resetModalExportFacture: false,
      menu: false,
      isLocalStorageOrganisme: [],
      isLocalStorageFiliale: [],
      isLocalStorageLot: [],
      loading: false
    };
  },
  methods: {
    ...mapActions([
      'generateFactureMastersObliges',
      'getAppelPaiementObligeeThFiltred',
      'downloadFactureObligeeItem',
      'downloadFactureGlobaleObligeeItem',
      'updateSuiviPaiementDataTh',
      'getObligeeTemplateAndFiledsDataToUpdate',
      'getObligeeTemplateAndFiledsDataToUpdateForFcature',
      'uploadObligeeTemplateInServieVisionFiliale',
      'refreshAooelPaiementObligeeAction',
      'updateCoefObligee',
      'getAllDataForFilterObligee',
      'updateSuiviPaiementStatutTh',
      'downloadPaiementObligeeThFiltredExcel',
      'sendMailVertigo',
      'uploadObligeexls',
      'uploadObligeexlsAfterConfirmation',
      'downloadPaiementObligeeThFactureFiltredExcel',
      'downloadPaiementObligeeThFactureFiltredPdf',
      'uploadObligeAvoir',
      'validationFactureObligee',
      'fetchTypeListFactureObligee',
      'refreshFactureDossier',
      'exportRapportFactureObligee',
      'downloadMultiAvoirPdfs',
      'getSettingFilialeTh',
      'resetErrorAppelPaiement'
    ]),
    clickOpenHeaderIconPrime(header) {
      this['clickCount_' + header.for]++;
      if (this['clickCount_' + header.for] % 2 !== 0) {
        this['details_' + header.for] = true;
      } else {
        this['details_' + header.for] = false;
      }
    },
    clickOpenHeaderIconMissingPrime(header) {
      this['clickCount_' + header.for]++;
      if (this['clickCount_' + header.for] % 2 !== 0) {
        this['details_' + header.for] = true;
      } else {
        this['details_' + header.for] = false;
      }
    },
    clickOpenHeaderIconCommissionGestion(header) {
      this['clickCount_' + header.for]++;
      if (this['clickCount_' + header.for] % 2 !== 0) {
        this['details_' + header.for] = true;
      } else {
        this['details_' + header.for] = false;
      }
    },
    clickOpenHeaderIconCommission(header) {
      this['clickCount_' + header.for]++;
      if (this['clickCount_' + header.for] % 2 !== 0) {
        this['details_' + header.for] = true;
      } else {
        this['details_' + header.for] = false;
      }
    },
    clickOpenHeaderIconSupprime(header) {
      this['clickCount_' + header.for]++;
      if (this['clickCount_' + header.for] % 2 !== 0) {
        this['details_' + header.for] = true;
      } else {
        this['details_' + header.for] = false;
      }
    },
    clickOpenHeaderIconEnergieInvest(header) {
      this['clickCount_' + header.for]++;
      if (this['clickCount_' + header.for] % 2 !== 0) {
        this['details_' + header.for] = true;
      } else {
        this['details_' + header.for] = false;
      }
    },
    showRowDetailsVueDefault(item) {
      item.showDetails = !item.showDetails;
      if (item.showDetails == true) {
        if (!this.expanded.includes(item)) {
          this.expanded.push(item);
        }
      }
    },
    openPopover() {
      this.menu = true;
    },
    closePopover() {
      this.menu = false;
      this.filterLot = [];
      this.filterFiliale = [];
      this.filterOrganisme = [];
      //RESET VILTER IN POPOVER
      this.isLocalStorageOrganisme = [];
      this.isLocalStorageFiliale = [];
      this.isLocalStorageLot = [];
      //END RESET FILTER IN POPOVER
      this.handleFilter();
    },
    validerPopover() {
      //SET FILTER FROM POPOVER
      this.filterOrganisme = this.isLocalStorageOrganisme;
      this.filterFiliale = this.isLocalStorageFiliale;
      this.filterLot = this.isLocalStorageLot;
      //END SET FILTER FROM POPOVER
      this.handleFilter();
      this.menu = false;
    },
    disabledStartDate(date) {
      if (this.date_fin) {
        return (
          this.date_fin && moment(date, 'YYYY-MM-DD') <= new Date(this.date_fin)
        );
      }
      return true;
    },
    disabledEndDate(date) {
      if (this.date_debut) {
        return (
          this.date_debut &&
          moment(date, 'YYYY-MM-DD').add(1, 'days') > new Date(this.date_debut)
        );
      }
      return true;
    },
    handleResetDate(name) {
      this[name] = null;
      this.getAllDataForFilterObligee({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
      this.handleFilter();
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.getAllDataForFilterObligee({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
      this.handleFilter();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
    remove(item, filter, fct, origin) {
      this[filter].splice(this[filter].indexOf(item), 1);
      if (origin) {
        this[origin] = this[filter];
      }
      this[fct]();
    },
    async openConfirmationGlobalModal() {
      this.confirmValidFcature = true;
      this.loader_list_facture = true;
      await this.fetchTypeListFactureObligee();
      this.type = this.getTypeListFactureObligee.map(item => item.value);
      this.loader_list_facture = false;
    },
    setVisibilityFieldsHeader(data) {
      // this.computedFields.map(item => {
      this['details_' + data.value] = !this['details_' + data.value];
      // if (item.key == data.key) {
      //   item.details = !item.details;
      // }
      // if (item.for == data.for && item.key != data.key) {
      //   item.views = !item.views;
      // }
      // });
    },
    rowClass(item, type) {
      return 'ligneNormale';
    },

    handleFilter() {
      this.setLocalStorageAppeloblige();
      this.$nextTick(async () => {
        this.getAppelPaiementObligeeThFiltred({
          lot: this.filterLot,
          organisme: this.filterOrganisme,
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          filiale: this.filterFiliale,
          page: this.page,
          per_page: this.per_page,
          search: this.searchValue
        });
      });
    },
    downloadFactureGlobale(item, type) {
      this.downloadFactureGlobaleObligeeItem({ item: item, type: type });
    },
    downloadFactureItem(item, type, id) {
      this.downloadFactureObligeeItem({
        id: id
      });
    },
    async refreshAooelPaiementObligee() {
      let tab = [];
      this.getAppeliPaiementThTableObligee.map(row =>
        row.lots.map(l => {
          if (l.projects.some(p => p.check == true)) {
            tab.push(l.name);
          }
        })
      );
      const response = await this.refreshAooelPaiementObligeeAction({
        lot: tab
      });
      if (response == true) {
        this.handleFilter();
      }
    },
    setLocalStorageAppeloblige() {
      localStorage.setItem(
        'appelOblige',
        JSON.stringify({
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          organisme: this.filterOrganisme,
          lot: this.filterLot,
          filiale: this.filterFiliale,
          search: this.searchValue
        })
      );
    },
    checkAllFunction() {
      this.getAppeliPaiementThTableObligee.map(item => {
        item.check = this.checkAll;
        item.lots.map(itemD => {
          itemD.check = this.checkAll;
          itemD.projects.map(itemP => {
            itemP.check = this.checkAll;
          });
        });
      });
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.lots.map(itemD => {
        itemD.check = item.check;
        itemD.projects.map(itemP => {
          itemP.check = item.check;
        });
      });
    },
    checkAllVerifDetails(data, item) {
      if (item.check == false && data.check == true) {
        data.check = false;
      }
      if (data.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.projects.map(itemP => {
        itemP.check = item.check;
      });
    },
    checkAllVerifDetailsDepot(data, item, itemD, index) {
      if (itemD.check == false && data.check == true) {
        data.check = false;
      }
      if (itemD.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      if (itemD.check == false && item.checkAll == true) {
        item.checkAll = false;
      }
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
      this.checkAll = false;
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
      this.checkAll = false;
    },
    async validationGlobalFactureObligee() {
      this.error = null;
      if (this.type.length == 0) {
        this.error = 'Il faut sélectionner au moins un type';
      } else {
        this.loading = true;
        const response = await this.validationFactureObligee({
          lot: this.filterLot,
          organisme: this.filterOrganisme,
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          filiale: this.filterFiliale,
          dataCheck: this.computedgetLotsToSend,
          type: this.type
        });
        if (response) {
          this.confirmValidFcature = false;
          this.$swal
            .fire({
              icon: 'info',
              confirmButtonText: 'Ok',
              html:
                '<h5>Nombre des factures générées ' +
                this.getResponsevalidationGlobal.success +
                '</h2><br>' +
                '<h5>Nombre des factures déjà existantes ' +
                this.getResponsevalidationGlobal.exist +
                '</h5><br>' +
                '<h5>Nombre des factures erronées ' +
                this.getResponsevalidationGlobal.error +
                '</h5>'
            })
            .then(result => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.handleFilter();
              }
            });
        }
        this.loading = false;
      }
    },
    exportToExcel() {
      this.downloadPaiementObligeeThFiltredExcel({
        lot: this.filterLot,
        organisme: this.filterOrganisme,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        filiale: this.filterFiliale,
        exportExcel: true,
        dataCheck: this.computedgetLotsToSend
      });
    },
    async exportExcelFacture(selectedModel, type) {
      const response = await this.downloadPaiementObligeeThFactureFiltredExcel({
        lot: this.filterLot,
        organisme: this.filterOrganisme,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        filiale: this.filterFiliale,
        modelExport_id: selectedModel,
        famille: 'oblige',
        dataCheck: this.computedgetLotsToSend,
        type: type
      });
      if (response) {
        this.resetModalExportFacture = !this.resetModalExportFacture;
      }
    },
    async exportFacturePdf(type) {
      await this.downloadPaiementObligeeThFactureFiltredPdf({
        dataCheck: this.computedCheckedRows,
        type: type
      });
    },
    handleUploadToExcel() {
      this.insertionXls = true;
    },
    resetModal() {
      this.error = null;
      this.type = [];
      this.checkAllType = true;
      this.loadingUpload = false;
      this.errorUpload = null;
      this.file = null;
    },
    hideModal(ref) {
      this[ref] = false;
      this.resetErrorAppelPaiement();
      this.resetModal();
    },
    async change() {
      this.loadingUpload = true;
      if (this.file !== null) {
        const payload = {
          file: this.file

          // depot: this.depot
        };
        const response = await this.uploadObligeexls(payload);
        if (response.succes) {
          this.loadingUpload = false;
          if (response.data && response.data.data) {
            if (response.data.data.length) {
              let extra =
                "<div style='margin: auto 10%;height: 260px;overflow-x: auto'> <h4><i>Dossier(s) existe(nt) :" +
                response.data.data.length +
                ' </i></h4>';

              for (const [key, value] of Object.entries(response.data.data)) {
                extra =
                  extra +
                  '<li style="  font-size: 12px;float: left;width: 100%;"> <b>Dossier ID: </b>' +
                  value.dossier_ID +
                  '<br><b>Numéro dossier: </b>' +
                  value.numero_dossier +
                  '</li>';
              }
              extra = extra + '</div>';
              this.$swal
                .fire({
                  background: 'rgb(245 245 252)',
                  title:
                    'Êtes-vous sur de vouloir écraser les dossiers dupliquer (Le montant min sera recalculé)',
                  type: 'warning',
                  icon: 'warning',
                  locale: 'fr',
                  allowOutsideClick: false,
                  showCloseButton: true,
                  showConfirmButton: true,
                  showCancelButton: true,
                  html: extra,
                  confirmButtonText: 'Oui',
                  cancelButtonText: 'Non',
                  confirmButtonClass: 'btn btn-success',
                  cancelButtonClass: 'btn btn-danger',
                  cancelButtonColor: '#d33',
                  customClass: {
                    actions: 'my-actions',
                    cancelButton: 'order-2 ',
                    confirmButton: 'order-1'
                  }
                })
                .then(async result => {
                  let force = '';
                  if (result.isDismissed && result.dismiss != 'close')
                    force = 'no';
                  if (result.isConfirmed) force = 'oui';
                  if (this.file !== null && force != '') {
                    const payload = {
                      file: this.file,
                      force: force
                      // depot: this.depot
                    };
                    this.loadingUpload = true;
                    const response = await this.uploadObligeexlsAfterConfirmation(
                      payload
                    );
                    this.loadingUpload = false;
                    this.$refs['uploadPaiementObligeeTemplateModal'].hide();
                    this.handleFilter();
                  }
                });
            } else {
              if (this.file !== null) {
                const payload = {
                  file: this.file,
                  force: 'non'
                  // depot: this.depot
                };
                this.loadingUpload = true;
                const response = await this.uploadObligeexlsAfterConfirmation(
                  payload
                );
                this.loadingUpload = false;
                this.$refs['uploadPaiementObligeeTemplateModal'].hide();
                this.handleFilter();
              }
            }
          }
        } else {
          this.loadingUpload = false;
          if (
            this.geterrorObligeXls &&
            (this.geterrorObligeXls.error || this.geterrorObligeXls.missing)
          ) {
            let htmlContent = '';
            let error = '';
            if (this.geterrorObligeXls.error) {
              let error = this.geterrorObligeXls.error;
              htmlContent = error;
            }
            let missing = this.geterrorObligeXls.missing
              ? '<div><h4><i>Colonnes manquantes :</i></h4>'
              : '';
            if (this.geterrorObligeXls.missing) {
              for (const [key, value] of Object.entries(
                this.geterrorObligeXls.missing
              )) {
                missing = missing + '<li>' + value + '</li>';
              }
              missing = missing + '</div>';
              htmlContent =
                "<div style='margin: auto;height: 260px;overflow-x: auto;' class='error-upload-scroll'>" +
                missing +
                '</div>';
            }

            this.$swal.fire({
              background: 'rgb(245 245 252)',
              type: 'error',
              icon: 'error',
              html: htmlContent,
              allowOutsideClick: false,
              showCloseButton: true
            });
          }
        }
      }
    },
    handleFileDrop(e) {
      document.getElementById('uploadInput').value = '';
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach(f => {
        this.file = f;
      });
    },
    handleFileInput(e) {
      let files = e.target.files;
      files = e.target.files;
      if (!files) return;
      [...files].forEach(f => {
        this.file = f;
      });
      document.getElementById('uploadInput').value = '';
    },
    changeAllCheckTypes() {
      this.type =
        this.checkAllType == true
          ? this.getTypeListFactureObligee.map(item => item.value)
          : [];
    }
  },
  computed: {
    ...mapGetters([
      'checkPermission',
      'missingOrganismeInObligeeFile',
      'getChargementAppelPaiementThObligee',
      'getErrorAppeliPaiementThObligee',
      'getErrorAppeliPaiementUpdateThObligee',
      'getAppeliPaiementThTableObligee',
      'getTotalAppelPaiementObligee',
      'getListOrganismeObligee',
      'geTtotalRowsThObligee',
      'getListInstallateurbligee',
      'getListLotsOligee',
      'getLoadingDataFilterObligeeThProjects',
      'getMailLoadingVertigo',
      'geterrorObligeXls',
      'getResponsevalidationGlobal',
      'getLoaderDelteProjectVertigo',
      'getErrorDelteProjectVertigo',
      'getTypeListFactureObligee',
      'getLoadingUploadFileCdg',
      'getShowNotifExportFile',
      'countNonEmptyArrays'
    ]),

    headersSum() {
      let tableContent = [
        {
          text: 'NB dossier',
          value: 'computedTotalProjectSelected',
          show: true,
          noUnite: true
        },
        { text: 'Reste à charge', value: 'computedRestAcharge', show: true },
        {
          text: "Montant TTC de l'avoir",
          value: 'computedMontantTtcAvoir',
          show: true
        },
        {
          text: 'Montant Total TTC',
          value: 'computedMontantTotalTtc',
          show: true
        },
        {
          text: 'Montant Prime CEE',
          value: 'computedMontantPrimeCee',
          show: true
        },
        { text: 'Kwh cumac', value: 'computedMontantkwh', show: true },
        { text: 'Mega cumac', value: 'computedMontantMegaCumac', show: true },
        {
          text: 'Prime Installateur',
          value: 'computedMontantPrimeInstallateur',

          show: this.checkPermission('GAPTHACP')
        },
        {
          text: 'Prime Installateur TTC',
          value: 'computedMontantPrimeInstallateurTTC',

          show: this.checkPermission('GAPTHACP')
        },
        {
          text: 'Prime avoir',
          value: 'computedMontantPrimeAvoir',

          show: this.checkPermission('GAPTHACAP')
        },
        {
          text: 'Comm Installateur',
          value: 'computedMontantCommInstallateur',

          show: this.checkPermission('GAPTHACI')
        },
        {
          text: 'Comm Installateur TTC',
          value: 'computedMontantCommInstallateurTTC',

          show: this.checkPermission('GAPTHACI')
        },
        {
          text: 'Comm Installateur TVA',
          value: 'computedMontantCommInstallateurTVA',

          show: this.checkPermission('GAPTHACI')
        },
        {
          text: 'Comm avoir',
          value: 'computedMontantMissingPrimeAvoir',

          show: this.checkPermission('GAPTHACAI')
        },
        {
          text: 'Commission gestion HT',
          value: 'computedMontantCommissionGestion',

          show: this.checkPermission('GAPTHACCG')
        },
        {
          text: 'Commission gestion TTC',
          value: 'computedMontantCommissionGestionTTC',

          show: this.checkPermission('GAPTHACCG')
        },
        {
          text: 'Commission gestion TVA',
          value: 'computedMontantCommissionGestionTVA',

          show: this.checkPermission('GAPTHACCG')
        },
        {
          text: 'Commission gestion avoir',
          value: 'computedMontantCommissionGestionAvoir',

          show: this.checkPermission('GAPTHFACG')
        },
        {
          text: 'Commission',
          value: 'computedMontantCommission',

          show: this.checkPermission('GAPTHACC')
        },
        {
          text: 'Commission TTC',
          value: 'computedMontantCommissionTTC',

          show: this.checkPermission('GAPTHACC')
        },
        {
          text: 'Commission TVA',
          value: 'computedMontantCommissionTVA',

          show: this.checkPermission('GAPTHACC')
        },
        {
          text: 'Commission avoir',
          value: 'computedMontantCommissionAvoir',

          show: this.checkPermission('GAPTHACAC')
        },
        {
          text: 'Surprime HT',
          value: 'computedMontantSurprime',

          show: this.checkPermission('GAPTHACS')
        },
        {
          text: 'Surprime TTC',
          value: 'computedMontantSurprimeTTC',

          show: this.checkPermission('GAPTHACS')
        },
        {
          text: 'Surprime TVA',
          value: 'computedMontantSurprimeTVA',

          show: this.checkPermission('GAPTHACS')
        },
        {
          text: 'Surprime avoir',
          value: 'computedMontantSurprimeAvoir',

          show: this.checkPermission('GAPTHACAS')
        },
        {
          text: 'Consolidée Energie Invest HT',
          value: 'computedMontantConsolideeEnergieInvest',

          show: this.checkPermission('GAPTHACFCEI')
        },
        {
          text: 'Consolidée Energie Invest TVA',
          value: 'computedMontantConsolideeEnergieInvestTVA',

          show: this.checkPermission('GAPTHACFCEI')
        },
        {
          text: 'Consolidée Energie Invest TTC',
          value: 'computedMontantConsolideeEnergieInvestTTC',

          show: this.checkPermission('GAPTHACFCEI')
        },
        {
          text: 'Consolidée Energie Invest avoir',
          value: 'computedMontantConsolideeEnergieInvestAvoir',

          show: this.checkPermission('GAPTHACFCEI')
        }
      ];
      return tableContent.filter(i => i.show == true);
    },
    computedData() {
      const computedDataValue = {
        computedTotalProjectSelected: this.computedTotalProjectSelected,
        computedRestAcharge: this.computedRestAcharge,
        computedMontantTtcAvoir: this.computedMontantTtcAvoir,
        computedMontantTotalTtc: this.computedMontantTotalTtc,
        computedMontantPrimeCee: this.computedMontantPrimeCee,
        computedMontantkwh: this.computedMontantkwh,
        computedMontantMegaCumac: this.computedMontantMegaCumac,
        computedMontantPrimeInstallateur: this.computedMontantPrimeInstallateur,
        computedMontantPrimeInstallateurTTC: this
          .computedMontantPrimeInstallateurTTC,
        computedMontantPrimeAvoir: this.computedMontantPrimeAvoir,
        computedMontantCommInstallateur: this.computedMontantCommInstallateur,
        computedMontantCommInstallateurTTC: this
          .computedMontantCommInstallateurTTC,
        computedMontantCommInstallateurTVA: this
          .computedMontantCommInstallateurTVA,
        computedMontantMissingPrimeAvoir: this.computedMontantMissingPrimeAvoir,
        computedMontantCommissionGestion: this.computedMontantCommissionGestion,
        computedMontantCommissionGestionTTC: this
          .computedMontantCommissionGestionTTC,
        computedMontantCommissionGestionTVA: this
          .computedMontantCommissionGestionTVA,
        computedMontantCommissionGestionAvoir: this
          .computedMontantCommissionGestionAvoir,
        computedMontantCommission: this.computedMontantCommission,
        computedMontantCommissionTTC: this.computedMontantCommissionTTC,
        computedMontantCommissionTVA: this.computedMontantCommissionTVA,
        computedMontantCommissionAvoir: this.computedMontantCommissionAvoir,
        computedMontantSurprime: this.computedMontantSurprime,
        computedMontantSurprimeTTC: this.computedMontantSurprimeTTC,
        computedMontantSurprimeTVA: this.computedMontantSurprimeTVA,
        computedMontantSurprimeAvoir: this.computedMontantSurprimeAvoir,
        computedMontantConsolideeEnergieInvest: this
          .computedMontantConsolideeEnergieInvest,
        computedMontantConsolideeEnergieInvestTVA: this
          .computedMontantConsolideeEnergieInvestTVA,
        computedMontantConsolideeEnergieInvestTTC: this
          .computedMontantConsolideeEnergieInvestTTC,
        computedMontantConsolideeEnergieInvestAvoir: this
          .computedMontantConsolideeEnergieInvestAvoir
      };

      const visibleColumns = this.headersSum.map(header => header.value);
      const filteredData = {};

      visibleColumns.forEach(column => {
        let unite = this.headersSum.filter(i => i.value == column);
        unite = unite.length && unite[0].noUnite ? '' : ' €';
        filteredData[column] = computedDataValue[column] + unite;
      });

      return [filteredData];
    },
    computedLengthFilter() {
      return this.countNonEmptyArrays(
        this.filterOrganisme,
        this.filterFiliale,
        this.filterLot
      );
    },
    returnTableChips() {
      if (
        this.date_debut !== null ||
        this.date_fin !== null ||
        this.searchValue !== null
      ) {
        return true;
      }
      return false;
    },
    returnTableFiltre() {
      if (
        this.filterOrganisme.length ||
        this.filterFiliale.length ||
        this.filterLot.length
      ) {
        return true;
      }
      return false;
    },
    totalPages() {
      if (this.geTtotalRowsThObligee) {
        return Math.ceil(this.geTtotalRowsThObligee / this.per_page);
      }
      return this.geTtotalRowsThObligee;
    },
    ComputedFilter() {
      return {
        filterOrganisme: this.filterOrganisme,
        filterFiliale: this.filterFiliale,
        filterLot: this.filterLot,
        num: this.searchValue,
        date_debut: this.date_debut,
        date_fin: this.date_fin
      };
    },
    computedCHeckFiliale() {
      return function(data) {
        let check = true;

        if (data) {
          data.lots.forEach(item => {
            if (
              (!item.missing_prime_id && item.missing_prime_ht != '0.00') ||
              (!item.prime_id && item.prime_ht != '0.00') ||
              (((!item.commission_id && item.commission_ht != '0.00') ||
                (!item.surprime_id && item.surprime_ht != '0.00') ||
                (!item.commission_gestion_id &&
                  item.commission_gestion_ht != '0.00')) &&
                (!item.consolidee_energie_invest_id ||
                  item.consolidee_energie_invest_ht == '0.00'))
            ) {
              check = false;
              return;
            }
          });
        }
        return check;
      };
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 233 + heigthBlock;
      if (this.computedLineFilter) {
        hei = hei + 30;
      }
      if (this.computedTotalProjectSelected > 0) {
        hei = hei + 25;
      }
      if (this.showaTableSum == true && this.computedTotalProjectSelected > 0) {
        hei = hei + 130;
      }

      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeBlockTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }

        // table.forEach(element => {
        //   table2.push(element.clientHeight);
        // });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 173 + heigthBlock;

      if (this.computedLineFilter) {
        hei = hei + 30;
      }
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    computedCheckChipFilter() {
      if (
        this.filterOrganisme.length > 0 ||
        this.filterFiliale.length > 0 ||
        this.filterLot.length > 0
      ) {
        return true;
      }
      return false;
    },
    computedLineFilter() {
      if (this.date_debut || this.date_fin) {
        return true;
      }
      return false;
    },
    computedResetModalExportFacture() {
      return this.resetModalExportFacture;
    },
    computedgetLotsToSend() {
      let lotsToSend = [];
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          if (item2.check === true) {
            lotsToSend.push(item2.name);
          }
        })
      );
      return lotsToSend;
    },
    computedShowSubRow() {
      return function(data) {
        return this['details_' + data];
        // return this.computedFields.filter(item => item.key == data)[0]?.details;
      };
    },
    computedFields() {
      let fields = [
        {
          value: 'check_all',
          text: '',
          views: true,
          for: 'all',
          sortable: false,
          show: true,
          width: '40px !important',
          class: 'sticky-header-1-oblige'
        },
        {
          value: 'data-table-expand',
          text: '',
          views: true,
          for: 'all',
          sortable: false,
          width: '40px !important',
          class: 'sticky-header-2-oblige',
          show: true
        },
        {
          value: 'name',
          text: 'Numéro de lot',
          for: 'all',
          sortable: false,
          views: true,
          class: 'sticky-header-3-oblige',
          show: true
        },
        {
          value: 'nom',
          text: 'Nom et prénom',
          for: 'all',
          sortable: false,
          views: true,
          show: true
        },
        {
          value: 'organisme',
          text: 'Organisme',
          for: 'all',
          sortable: false,
          views: true,
          show: true
        },
        {
          value: 'reste_a_charge',
          text: 'Reste à charge',
          for: 'all',
          sortable: false,
          views: true,
          show: true
        },
        {
          value: 'montant_ttc_avoir',
          text: "Montant TTC de l'avoir",
          for: 'all',
          sortable: false,
          views: true,
          show: true
        },
        {
          value: 'montant_total_ttc',
          text: 'Montant Total TTC',
          for: 'all',
          sortable: false,
          views: true,
          show: true
        },
        {
          value: 'montant_prime_cee',
          text: 'Montant Prime CEE',
          for: 'all',
          sortable: false,
          views: true,
          show: true
        },
        {
          value: 'kwh_cumac',
          text: 'Kwh cumac',
          for: 'all',
          sortable: false,
          views: true,
          show: true
        },

        {
          value: 'm_cumac',
          text: 'Mega cumac',
          for: 'all',
          sortable: false,
          views: true,
          show: true
        },
        {
          value: 'coef_prime_installateur',
          text: 'Coef prime installateur',
          for: 'all',
          sortable: false,
          views: true,
          coef: true,
          show: true
        },
        {
          value: 'prime',
          text: 'Prime Installateur',
          for: 'prime',
          views: true,
          details: false,
          sortable: false,
          class: 'prime-bg',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACP')
        },
        {
          value: 'prime_pu',
          text: 'Coef prime',
          for: 'prime',
          sortable: false,
          views: this.details_prime,
          class: 'prime-bg',
          show: this.checkPermission('GAPTHACP')
        },
        {
          value: 'prime_ttc',
          text: 'Mt  TTC',
          for: 'prime',
          sortable: false,
          views: this.details_prime,
          class: 'prime-bg',
          show: this.checkPermission('GAPTHACP')
        },
        {
          value: 'prime_tva',
          text: 'Mt  TVA',
          for: 'prime',
          sortable: false,
          views: this.details_prime,
          class: 'prime-bg',
          show: this.checkPermission('GAPTHACP')
        },
        // {
        //   value: 'prime_statut',
        //   text: 'Statut',
        //   for: 'prime',
        //   views: this.details_prime,
        //   thClass: 'prime-bg',
        //   statue: true,
        //   show: this.checkPermission('GAPTHACP'),
        //   editable: this.checkPermission('GAPTHMSPG')
        // },
        {
          value: 'prime_num_facture',
          text: 'Numéro de facture',
          for: 'prime',
          views: this.details_prime,
          sortable: false,
          class: 'prime-bg',
          show: this.checkPermission('GAPTHACP')
        },
        {
          value: 'prime_installateur_avoir',
          text: 'Prime Installateur Avoir',
          for: 'all',
          sortable: false,
          views: true,
          class: 'prime-bg',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACAP')
        },
        {
          value: 'missing_prime',
          text: 'Comm installateur',
          for: 'missing_prime',
          views: true,
          details: false,
          sortable: false,
          class: 'missing-bg',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACI')
        },
        {
          value: 'missing_prime_pu',
          text: 'Coef Comm',
          for: 'missing_prime',
          sortable: false,
          views: this.details_missing_prime,
          class: 'missing-bg',
          show: this.checkPermission('GAPTHACI')
        },
        {
          value: 'missing_prime_ttc',
          text: 'Mt TTC',
          for: 'missing_prime',
          sortable: false,
          views: this.details_missing_prime,
          class: 'missing-bg',
          show: this.checkPermission('GAPTHACI')
        },

        {
          value: 'missing_prime_tva',
          text: 'Mt TVA',
          for: 'missing_prime',
          sortable: false,
          views: this.details_missing_prime,
          class: 'missing-bg',
          show: this.checkPermission('GAPTHACI')
        },
        // {
        //   value: 'missing_prime_statut',
        //   text: 'Statut',
        //   for: 'missing_prime',
        //   views: this.details_missing_prime,
        //   class: 'missing-bg',
        //   statue: true,
        //   show: this.checkPermission('GAPTHACI'),
        //   editable: this.checkPermission('GAPTHMSPIG')
        // },
        {
          value: 'missing_prime_num_facture',
          text: 'Numéro de facture',
          for: 'missing_prime',
          sortable: false,
          views: this.details_missing_prime,
          class: 'missing-bg',
          show: this.checkPermission('GAPTHACI')
        },
        {
          value: 'comm_installateur_avoir',
          text: 'Comm Installateur Avoir',
          for: 'all',
          sortable: false,
          views: true,
          class: 'missing-bg',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACAI')
        },

        //commission gestion fields
        {
          value: 'commission_gestion',
          text: 'Commission gestion ht',
          for: 'commission_gestion',
          sortable: false,
          views: true,
          details: false,
          class: 'commission-gestion-bg',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACCG')
        },
        {
          value: 'commission_gestion_pu',
          text: 'Coef Commission gestion',
          coef: true,
          for: 'commission_gestion',
          views: this.details_commission_gestion,
          sortable: false,
          class: 'commission-gestion-bg',
          show: this.checkPermission('GAPTHACCG')
        },
        {
          value: 'commission_gestion_ttc',
          text: 'Mt TTC',
          for: 'commission_gestion',
          sortable: false,
          views: this.details_commission_gestion,
          class: 'commission-gestion-bg',
          show: this.checkPermission('GAPTHACCG')
        },

        {
          value: 'commission_gestion_tva',
          text: 'Mt TVA',
          for: 'commission_gestion',
          sortable: false,
          views: this.details_commission_gestion,
          class: 'commission-gestion-bg',
          show: this.checkPermission('GAPTHACCG')
        },
        // {
        //   value: 'commission_gestion_statut',
        //   text: 'Statut',
        //   for: 'commission_gestion',
        //   views: this.details_commission_gestion,
        //   class: 'commission-gestion-bg',
        //   statue: true,
        //   show: this.checkPermission('GAPTHACCG'),
        //   editable: this.checkPermission('GAPTHMGSCG')
        // },

        {
          value: 'commission_gestion_num_facture',
          text: 'Numéro de facture',
          for: 'commission_gestion',
          sortable: false,
          views: this.details_commission_gestion,
          class: 'commission-gestion-bg',
          show: this.checkPermission('GAPTHACCG')
        },
        {
          value: 'commission_gestion_avoir',
          text: 'Commission gestion Avoir',
          for: 'all',
          sortable: false,
          views: true,
          class: 'commission-gestion-bg',
          show: this.checkPermission('GAPTHACACG')
        },

        {
          value: 'commission',
          text: 'Commission',
          for: 'commission',
          sortable: false,
          views: true,
          details: false,
          class: 'commission-bg',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACC')
        },
        {
          value: 'commission_pu',
          text: 'Coef commission',
          coef: true,
          for: 'commission',
          sortable: false,
          views: this.details_commission,
          class: 'commission-bg',
          show: this.checkPermission('GAPTHACC')
        },
        {
          value: 'commission_ttc',
          text: 'MT TTC',
          for: 'commission',
          sortable: false,
          views: this.details_commission,
          class: 'commission-bg',
          show: this.checkPermission('GAPTHACC')
        },

        {
          value: 'commission_tva',
          text: 'Mt TVA',
          for: 'commission',
          sortable: false,
          views: this.details_commission,
          class: 'commission-bg',
          show: this.checkPermission('GAPTHACC')
        },
        // {
        //   value: 'commission_statut',
        //   text: 'Statut',
        //   for: 'commmision',
        //   views: this.details_commission,
        //   class: 'commission-bg',
        //   statue: true,
        //   show: this.checkPermission('GAPTHACC'),
        //   editable: this.checkPermission('GAPTHMSPCG')
        // },
        {
          value: 'commission_num_facture',
          text: 'Numéro de facture',
          for: 'commission',
          sortable: false,
          views: this.details_commission,
          class: 'commission-bg',
          show: this.checkPermission('GAPTHACC')
        },
        {
          value: 'commission_avoir',
          text: 'Commission Avoir',
          for: 'all',
          sortable: false,
          views: true,
          class: 'commission-bg',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACAC')
        },
        {
          value: 'surprime',
          text: 'Surprime ht',
          for: 'surprime',
          sortable: false,
          views: true,
          details: false,
          class: 'surprime-bg',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACS')
        },
        {
          value: 'surprime_pu',
          text: 'Surprime  Coef',
          coef: true,
          for: 'surprime',
          sortable: false,
          views: this.details_surprime,
          class: 'surprime-bg',
          show: this.checkPermission('GAPTHACS')
        },
        {
          value: 'surprime_ttc',
          text: 'Mt TTC',
          for: 'surprime',
          sortable: false,
          views: this.details_surprime,
          class: 'surprime-bg',
          show: this.checkPermission('GAPTHACS')
        },

        {
          value: 'surprime_tva',
          text: 'Mt TVA',
          for: 'surprime',
          sortable: false,
          views: this.details_surprime,
          class: 'surprime-bg',
          show: this.checkPermission('GAPTHACS')
        },
        // {
        //   value: 'surprime_statut',
        //   text: 'Statut',
        //   for: 'surprime',
        //   views: this.details_surprime,
        //   class: 'surprime-bg',
        //   statue: true,
        //   show: this.checkPermission('GAPTHACS'),
        //   editable: this.checkPermission('GAPTHMSPSG')
        // },
        {
          value: 'surprime_num_facture',
          text: 'Numéro de facture',
          for: 'surprime',
          sortable: false,
          views: this.details_surprime,
          class: 'surprime-bg',
          show: this.checkPermission('GAPTHACS')
        },
        {
          value: 'surprime_avoir',
          text: 'Surprime Avoir',
          for: 'all',
          sortable: false,
          views: true,
          class: 'surprime-bg',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACAS')
        },
        //Facture consolidée (Installateur)
        {
          value: 'consolidee_energie_invest',
          text: 'Facture consolidée (Energie invest)',
          for: 'consolidee_energie_invest',
          sortable: false,
          views: true,
          details: false,
          class: 'consolidee-energie-invest-bg',
          tdClass: 'col-montant-obligee',
          show: this.checkPermission('GAPTHACFCEI')
        },
        {
          value: 'consolidee_energie_invest_pu',
          text: 'Coef Facture consolidée (Installateur)',
          coef: true,
          for: 'consolidee_energie_invest',
          sortable: false,
          views: this.details_consolidee_energie_invest,
          class: 'consolidee-energie-invest-bg',
          show: this.checkPermission('GAPTHACFCEI')
        },
        {
          value: 'consolidee_energie_invest_ttc',
          text: 'Mt TTC',
          for: 'consolidee_energie_invest',
          sortable: false,
          views: this.details_consolidee_energie_invest,
          class: 'consolidee-energie-invest-bg',
          show: this.checkPermission('GAPTHACFCEI')
        },

        {
          value: 'consolidee_energie_invest_tva',
          text: 'Mt TVA',
          for: 'consolidee_energie_invest',
          sortable: false,
          views: this.details_consolidee_energie_invest,
          class: 'consolidee-energie-invest-bg',
          show: this.checkPermission('GAPTHACFCEI')
        },
        // {
        //   value: 'commission_gestion_statut',
        //   text: 'Statut',
        //   for: 'commition gestion',
        //   views: this.details_commission_gestion,
        //   class: 'consolidee-energie-invest-bg',
        //   statue: true,
        //   show: this.checkPermission('GAPTHACCG'),
        //   editable: this.checkPermission('GAPTHMGSCG')
        // },

        {
          value: 'consolidee_energie_invest_num_facture',
          text: 'Numéro de facture',
          for: 'consolidee_energie_invest',
          sortable: false,
          views: this.details_consolidee_energie_invest,
          class: 'consolidee-energie-invest-bg',
          show: this.checkPermission('GAPTHACFCEI')
        },
        {
          value: 'consolidee_energie_invest_avoir',
          text: 'Facture consolide Avoir',
          for: 'all',
          sortable: false,
          views: true,
          class: 'consolidee-energie-invest-bg',
          show: this.checkPermission('GAPTHACACEI')
        },
        {
          value: 'total',
          text: 'Total Coef',
          for: 'all',
          sortable: false,
          views: true,
          class: 'th-total-coef-oblige',
          tdClass: 'td-total-coef-oblige',
          show: true
        }
      ];
      return fields;
    },
    computedFields2() {
      return this.computedFields.filter(
        item => item.show == true && item.views == true
      );
    },
    computedHeaderVisibility() {
      return function(data) {
        if (
          data == 'prime' ||
          data == 'surprime' ||
          data == 'missing_prime' ||
          data == 'commission_gestion' ||
          data == 'commission' ||
          data == 'consolidee_energie_invest'
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedCantUpdate() {
      return false;
    },
    computedTotalDepot() {
      return function(data) {
        if (data) {
          let x =
            parseFloat(data.commission_pu.replace(' ', '')) +
            parseFloat(data.prime_pu.replace(' ', '')) +
            parseFloat(data.surprime_pu.replace(' ', '')) +
            parseFloat(data.missing_prime_pu.replace(' ', ''));
          let res = x.toFixed(2);
          return res;
        }
        return '00.00';
      };
    },
    computedTotal() {
      return function(data) {
        if (data) {
          let x =
            parseFloat(data.commission.replace(' ', '')) +
            parseFloat(data.prime.replace(' ', '')) +
            parseFloat(data.surprime.replace(' ', '')) +
            parseFloat(data.missing_prime.replace(' ', ''));
          let res = x.toFixed(2);
          return res;
        }
        return '00.00';
      };
    },
    computedGetAppeliPaiementThTableObligeeCategories() {
      let table = [];
      if (
        this.getAppeliPaiementThTableObligee &&
        this.getAppeliPaiementThTableObligee.length
      ) {
        this.getAppeliPaiementThTableObligee.map(item => {
          table.push(item.name);
        });
      }

      return table;
    },
    computedGetAppeliPaiementThTableObligeeTotal_pu_commission() {
      let table = [];
      if (
        this.getAppeliPaiementThTableObligee &&
        this.getAppeliPaiementThTableObligee.length
      ) {
        this.getAppeliPaiementThTableObligee.map(item => {
          table.push(parseFloat(item.commission_pu));
        });
      }

      return table;
    },
    computedGetAppeliPaiementThTableObligeeTotal_pu_prime() {
      let table = [];
      if (
        this.getAppeliPaiementThTableObligee &&
        this.getAppeliPaiementThTableObligee.length
      ) {
        this.getAppeliPaiementThTableObligee.map(item => {
          table.push(parseFloat(item.prime_pu));
        });
      }

      return table;
    },
    computedGetAppeliPaiementThTableObligeeTotal_pu_missing_prime() {
      let table = [];
      if (
        this.getAppeliPaiementThTableObligee &&
        this.getAppeliPaiementThTableObligee.length
      ) {
        this.getAppeliPaiementThTableObligee.map(item => {
          table.push(parseFloat(item.missing_prime_pu));
        });
      }

      return table;
    },

    computedGetAppeliPaiementThTableObligeeTotal_pu_surprime() {
      let table = [];
      if (
        this.getAppeliPaiementThTableObligee &&
        this.getAppeliPaiementThTableObligee.length
      ) {
        this.getAppeliPaiementThTableObligee.map(item => {
          table.push(parseFloat(item.surprime_pu));
        });
      }

      return table;
    },
    chartOptions() {
      return {
        chart: {
          height: '100%',
          type: 'line',
          stacked: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [1, 1, 4]
        },
        title: {
          text: '',
          align: 'left',
          offsetX: 170
        },
        xaxis: {
          categories: this.computedGetAppeliPaiementThTableObligeeCategories
        },
        yaxis: [
          {
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: '#008FFB'
            },
            labels: {
              style: {
                colors: '#2a2a2a'
              }
            },
            // title: {
            //   text: 'Income (thousand crores)',
            //   style: {
            //     color: '#008FFB'
            //   }
            // },
            tooltip: {
              enabled: true
            }
          }
          // {
          //   seriesName: 'Income',
          //   opposite: true,
          //   axisTicks: {
          //     show: true
          //   },
          //   axisBorder: {
          //     show: true,
          //     color: '#00E396'
          //   },
          //   labels: {
          //     style: {
          //       colors: '#00E396'
          //     }
          //   },
          //   title: {
          //     text: 'Operating Cashflow (thousand crores)',
          //     style: {
          //       color: '#00E396'
          //     }
          //   }
          // }
          // {
          //   seriesName: 'Revenue',
          //   opposite: true,
          //   axisTicks: {
          //     show: true
          //   },
          //   axisBorder: {
          //     show: true,
          //     color: '#FEB019'
          //   },
          //   labels: {
          //     style: {
          //       colors: '#FEB019'
          //     }
          //   },
          //   title: {
          //     text: 'Revenue (thousand crores)',
          //     style: {
          //       color: '#FEB019'
          //     }
          //   }
          // }
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60
          }
        }
      };
    },
    series() {
      return [
        {
          name: 'Montant commission',
          type: 'column',
          data: this.computedGetAppeliPaiementThTableObligeeTotal_pu_commission,
          color: '#FF4560'
        },
        {
          name: 'Montant prime',
          type: 'column',
          data: this.computedGetAppeliPaiementThTableObligeeTotal_pu_prime,
          color: '#704ad1'
        },
        {
          name: 'Montant surprime',
          type: 'column',
          data: this.computedGetAppeliPaiementThTableObligeeTotal_pu_surprime
        },
        {
          name: 'Montant extrat',
          type: 'column',
          data: this
            .computedGetAppeliPaiementThTableObligeeTotal_pu_missing_prime,
          color: '#1ae9f3'
        }
      ];
    },
    computedDownloadGlobal() {
      return function(data) {
        for (let i = 0; i < data.item.length; i++) {
          if (data.item[i][data.column] == null) {
            return false;
          }
        }
        return true;
      };
    },
    computedCheckedRows() {
      let result1 = this.getAppeliPaiementThTableObligee.filter(row => {
        return row.lots.some(l => l.projects.some(p => p.check == true));
      });
      return result1;
    },
    computedCheckedRowsWithFact() {
      let result1 = this.getAppeliPaiementThTableObligee.filter(row => {
        return row.lots.some(
          l =>
            l.projects.some(p => p.check == true) &&
            l.missing_prime_factures.length == 0 &&
            l.prime_factures.length == 0 &&
            l.surprime_factures.length == 0 &&
            l.commission_factures.length == 0 &&
            l.commission_gestion_factures.length == 0 &&
            l.consolidee_energie_invest_factures.length == 0
        );
      });
      return result1;
    },
    computedCheckedRowsLots() {
      let result1 = this.getAppeliPaiementThTableObligee.filter(row => {
        return row.lots.some(l => l.check == true);
      });
      return result1;
    },
    computedAcheuteurToSend() {
      let listClient = [];
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            if (listClient.indexOf(item2.name_acheteur) === -1) {
              listClient.push(item2.name_acheteur);
            }
          }
        })
      );
      return listClient;
    },
    computedTotalProjectSelected() {
      let total = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                total++;
              }
            });
          }
        })
      );
      return total;
    },
    computedRestAcharge() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.reste_a_charge) * 100) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantTtcAvoir() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.montant_ttc_avoir) * 100) / 100 ||
                  0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantTotalTtc() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.montant_total_ttc) * 100) / 100 ||
                  0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantPrimeCee() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.montant_prime_cee) * 100) / 100 ||
                  0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantkwh() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.kwh_cumac) * 100) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantMegaCumac() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.m_cumac.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantPrimeInstallateur() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.prime_ht.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantPrimeInstallateurTTC() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.prime_ttc.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantPrimeInstallateurTVA() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.prime_tva.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommInstallateur() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (
                item3.check === true &&
                parseFloat(
                  item3.missing_prime_ht.replace(' ', '').replace(',', '.')
                ) > 0
              ) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.missing_prime_ht.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommInstallateurTTC() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (
                item3.check === true &&
                parseFloat(
                  item3.missing_prime_ttc.replace(' ', '').replace(',', '.')
                ) > 0
              ) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.missing_prime_ttc.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommInstallateurTVA() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (
                item3.check === true &&
                parseFloat(
                  item3.missing_prime_tva.replace(' ', '').replace(',', '.')
                ) > 0
              ) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.missing_prime_tva.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommission() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.commission_ht.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommissionTTC() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.commission_ttc.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommissionTVA() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.commission_tva) * 100) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantSurprime() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.surprime_ht.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantSurprimeTTC() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.surprime_ttc.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantSurprimeTVA() {
      let somme = 0;
      this.computedCheckedRows.map(item1 => {
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.surprime_tva.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        });
      });
      return somme;
    },
    computedMontantConsolideeEnergieInvest() {
      let somme = 0;
      this.computedCheckedRows.map(item1 => {
        item1?.lots?.map(item2 => {
          {
            // item2?.projects?.map(item3 => {
            if (
              item2.check === true &&
              parseFloat(
                item2?.consolidee_energie_invest_ht
                  ?.replace(' ', '')
                  .replace(',', '.')
              ) > 0
            ) {
              somme +=
                Math.round(
                  parseFloat(
                    item2?.consolidee_energie_invest_ht
                      ?.replace(' ', '')
                      .replace(',', '.')
                  ) * 100
                ) / 100 || 0;
            }
            // });
          }
        });
      });
      return somme;
      return 0;
    },

    computedMontantConsolideeEnergieInvestTVA() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1?.lots?.map(item2 => {
          {
            // item2?.projects?.map(item3 => {
            if (
              item2?.check === true &&
              parseFloat(
                item2?.consolidee_energie_invest_tva
                  ?.replace(' ', '')
                  .replace(',', '.')
              ) > 0
            ) {
              somme +=
                Math.round(
                  parseFloat(
                    item2?.consolidee_energie_invest_tva
                      ?.replace(' ', '')
                      .replace(',', '.')
                  ) * 100
                ) / 100 || 0;
            }
            // });
          }
        })
      );
      return somme;
    },
    computedMontantConsolideeEnergieInvestTTC() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1?.lots?.map(item2 => {
          {
            // item2?.projects?.map(item3 => {
            if (
              item2?.check === true &&
              parseFloat(
                item2?.consolidee_energie_invest_ttc
                  ?.replace(' ', '')
                  .replace(',', '.')
              ) > 0
            ) {
              somme +=
                Math.round(
                  parseFloat(
                    item2?.consolidee_energie_invest_ttc
                      ?.replace(' ', '')
                      .replace(',', '.')
                  ) * 100
                ) / 100 || 0;
            }
            // });
          }
        })
      );
      return somme;
    },
    computedMontantConsolideeEnergieInvestAvoir() {
      let somme = 0;
      this.computedCheckedRows.map(item1 => {
        item1?.lots?.map(item2 => {
          {
            if (item2.check === true) {
              somme +=
                Math.round(
                  parseFloat(item2.consolidee_energie_invest_avoir) * 100
                ) / 100 || 0;
            }
          }
        });
      });
      return somme;
    },
    //avoir
    computedMontantPrimeAvoir() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            if (item2.check === true) {
              somme +=
                Math.round(parseFloat(item2.prime_avoir) * 100) / 100 || 0;
            }
          }
        })
      );
      return somme;
    },
    computedMontantMissingPrimeAvoir() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            if (item2.check === true) {
              somme +=
                Math.round(parseFloat(item2.missing_prime_avoir) * 100) / 100 ||
                0;
            }
          }
        })
      );
      return somme;
    },
    computedMontantCommissionAvoir() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            if (item2.check === true) {
              somme +=
                Math.round(parseFloat(item2.commission_avoir) * 100) / 100 || 0;
            }
          }
        })
      );
      return somme;
    },
    computedMontantSurprimeAvoir() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            if (item2.check === true) {
              somme +=
                Math.round(parseFloat(item2.surprime_avoir) * 100) / 100 || 0;
            }
          }
        })
      );
      return somme;
    },
    computedMontantCommissionGestion() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.commission_gestion_ht
                        .replace(' ', '')
                        .replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommissionGestionTTC() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.commission_gestion_ttc
                        .replace(' ', '')
                        .replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommissionGestionTVA() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.commission_gestion_tva) * 100) /
                    100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommissionGestionAvoir() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            if (item2.check === true) {
              somme +=
                Math.round(parseFloat(item2.commission_gestion_avoir) * 100) /
                  100 || 0;
            }
          }
        })
      );
      return somme;
    }
  },

  components: {
    IconFiltre: () => import('@/assets/img/IconFiltre.vue'),
    PopoverObligeFacture: () =>
      import('@/views/component/Facture/PopoverObligeFacture.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
    GenerateFactureMaster: () =>
      import('./component/GenerateFactureMaster.vue'),
    exportRapportOblige: () => import('./exportRapportOblige.vue'),
    EditableInputSuiviTh: () =>
      import('@/views/component/EditableInputSuiviTh'),
    TemplateObligee: () => import('./component/templateObligee.vue'),
    columnHeaderWithToolTip: () =>
      import('./component/columnHeaderWithToolTip.vue'),
    ValidationStatueObligee: () =>
      import('./component/ValidationStatueObligee.vue'),
    MailComponent: () => import('./component/MailComponent.vue'),
    ExportExcelFacture: () => import('./component/exportExcelFacture'),
    ExportExcelFacturePdf: () => import('./component/exportExcelFacturePdf'),
    deleteProject: () => import('./component/deleteProject.vue'),
    vueObligeFacture: () =>
      import('./component/vue2Obligee/vueObligeFacture.vue'),
    InsertionBDD: () => import('@/views/InsertionBDD.vue')
  },
  async mounted() {
    this.date_debut = moment()
      .clone()
      .startOf('year')
      .format('YYYY-MM-DD');
    if (localStorage.getItem('appelOblige')) {
      let localData = JSON.parse(localStorage.getItem('appelOblige'));
      this.date_debut = localData.date_debut ? localData.date_debut : null;
      this.date_fin = localData.date_fin ? localData.date_fin : null;
      this.filterLot = localData.lot ? localData.lot : [];
      this.filterOrganisme = localData.organisme ? localData.organisme : [];
      this.filterFiliale = localData.filiale ? localData.filiale : [];
      this.searchValue = localData?.search ? localData?.search : null;
    } else {
      this.setLocalStorageAppeloblige();
    }
    this.getAllDataForFilterObligee({
      date_debut: this.date_debut,
      date_fin: this.date_fin
    });
    this.fetchTypeListFactureObligee();
    this.getSettingFilialeTh({ type: 'master filiale' });
    this.handleFilter();
  }
};
</script>

<style lang="scss">
.table-oblige {
  .sticky-header-2 {
    background-color: #fff;
    position: sticky;
    left: 32px !important;
    z-index: 4;
  }

  // border: 1px solid #e5e5e5;
  &.padding-table {
    padding-top: 19px;
  }

  .theme--light.v-data-table.v-data-table--fixed-header thead th {
    &.prime-bg {
      background: #fef2cb !important;
    }

    &.missing-bg {
      background-color: #dadada !important;
    }

    &.commission-gestion-bg {
      background-color: #12d0a7 !important;
      // white-space: nowrap;
    }

    &.commission-bg {
      background-color: #fbe4d5 !important;
    }

    &.surprime-bg {
      background-color: #b4c6e7 !important;
    }

    &.consolidee-energie-invest-bg {
      background-color: #e88793 !important;
    }
  }

  .th-total-coef-oblige {
    z-index: 8 !important;
    right: -10px !important;
    position: sticky;
  }

  .td-total-coef-oblige {
    right: -10px !important;
    position: sticky;
    z-index: 7;
    min-height: 29px;
    background-color: #fff;
  }

  .table-facture thead tr th td .v-input--selection-controls {
    margin-left: 6px;
  }
}

.space-table-filter-oblige-fac {
  padding-bottom: 1px !important;
}

.align-icon-flech-item {
  border-collapse: collapse;
  display: table;
}

.centre-col-td {
  line-height: 35px;
}

.centre-col-td-lot {
  line-height: 19px;
}

.bold-label {
  font-weight: 700;
}

.flex-div-annomalie {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
}

.rows-check {
  margin-top: 7px;
}

.text-avoir-color {
  color: red;
  white-space: nowrap;
}
</style>
